import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'maz-ui/css/main.css'
import 'vuetify/styles'
import i18next from 'i18next'
import I18NextVue from 'i18next-vue'
import vuetify from '@/plugins/vuetify'
import VueDOMPurifyHTML from 'vue-dompurify-html';

import { Chart, registerables } from 'chart.js'
import {ENVIRONMENT} from "./constants";
Chart.register(...registerables)

const app = createApp(App)

app.use(VueDOMPurifyHTML)
app.use(vuetify)
app.use(router)
app.use(store)
app.config.devtools = true
app.config.globalProperties.$window = window

if (['localhost'].includes(window.location.hostname)) {
  app.config.globalProperties.$BASE_URL = 'http://localhost:8081'
  app.config.globalProperties.$ENV = ENVIRONMENT.LOCAL
} else if (['app-dev.whistle-report.com'].includes(window.location.hostname)) {
  app.config.globalProperties.$BASE_URL = 'https://app-dev.whistle-report.com'
  app.config.globalProperties.$ENV = ENVIRONMENT.DEV
} else if (['app-test.whistle-report.com'].includes(window.location.hostname)) {
  app.config.globalProperties.$BASE_URL = 'https://app-test.whistle-report.com'
  app.config.globalProperties.$ENV = ENVIRONMENT.TEST
} else if (['demo.whistle-report.com'].includes(window.location.hostname)) {
  app.config.globalProperties.$BASE_URL = 'https://demo.whistle-report.com'
  app.config.globalProperties.$ENV = ENVIRONMENT.DEMO
} else if (['app.whistle-report.com'].includes(window.location.hostname)) {
  app.config.globalProperties.$BASE_URL = 'https://app.whistle-report.com'
  app.config.globalProperties.$ENV = ENVIRONMENT.PROD
}

app.use(I18NextVue, { i18next })
app.config.productionTip = false

if (!process.env.JEST_WORKER_ID) {
  app.mount('#app')
}
export const globalProperties = app.config.globalProperties
export const vue = app
