export default {

  setSignupStepper (state, stepper) {
    state.signupStepper = stepper
  },

  setSignupSme (state, signupSme) {
    state.signupSme = signupSme
  },

  setSignupPlan (state, plan) {
    state.signupSme.pricing_plan = plan
  }

}
