export default {
  hasAccess (state, getters) {
    if (getters.isLteAdmin) {
      return true
    }

    const organization = state.organizations.find(organization => organization.id === state.currentOrganizationId)
    if (!organization) {
      return false
    }

    if (organization.pricing_plan.training_video) {
      return true
    }
    return false
  },

  isBookable (state, getters) {
    if (getters.hasAccess) {
      return false
    }

    if (getters.accountSelected) {
      if (getters.hasRoleForOrganization(state.accountsFilter, 'ADMIN')) {
        return true
      }
    } else if (getters.hasRoleForAnyOrganization('ADMIN')) {
      return true
    }
  }
}
