import dayjs from "@/plugins/day"

export default {

  setInvoiceDateFilter (state, dateFilter) {
    state.invoiceTableOptions.dateFilter = dateFilter.map(date => dayjs(date).format('YYYY-MM-DD'))
  },

  setInvoices (state, invoices) {
    state.invoices = invoices
  }

}
