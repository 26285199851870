<template>
  <v-list density="compact" style='padding-top:0;margin-top:-1px; padding-bottom:0;border-bottom: 1px solid var(--border-gray);'>
    <v-list-item v-if="(canNavigateDashboard || canNavigateReports || canNavigateNotifications) && !miniVariant" key='actSeparator' title="">
      <v-list-item-title>{{ $t('navigation.act') }}</v-list-item-title>
    </v-list-item>
    <NavigationItem
      v-if="canNavigateDashboard"
      item-key="dashboard"
      route-name="Dashboard"
      icon-name="dashboard"
      title-label="navigation.dashboard"
    />

    <NavigationItem
      v-if="canNavigateReports"
      item-key="reports"
      route-name="SMEReports"
      icon-name="description"
      title-label="navigation.reports"
    />

    <NavigationItemNotification />
  </v-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavigationItem from './NavigationItem'
import NavigationItemNotification from './NavigationItemNotification'

export default {
  name: 'NavigationListReports',
  components: { NavigationItemNotification, NavigationItem },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant
    }),
    ...mapGetters([
      'canNavigateDashboard',
      'canNavigateReports',
      'canNavigateNotifications',
      'isGlobalUser',
      'isSupport'
    ])
  }
}
</script>
