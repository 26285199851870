<template>
  <div class="stepper"
  style="background: transparent; border: none; box-shadow: none; align-self: center;">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="step">
      <template v-if="!item.isNotShown">
        <v-icon v-if="modelValue> index +1" class="step-number v-stepper__step__step circle-check number-active" icon="check"></v-icon>
        <span v-else class="step-number v-stepper__step__step " :class='{"number-active": modelValue> index}'>{{items.filter(item => !item.isNotShown).indexOf(item) +1 }}</span>
        <div class="step-title" :class="{'title-active': modelValue > index }"> {{$t(item.title)}}</div>
      </template>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Stepper',
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    items: {
      default: []
    }
  },
  methods: {
    UpdateStep (index) {
      this.$emit('update:modelValue', index)
    }
  }
}
</script>

<style scoped >
body {
  box-shadow: none !important;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}
.editIcon {
  z-index: 2;
  background-color: transparent;
  padding: 20px;
}
.boxed {
  border-radius:4px
}
.divider {
  z-index: 1;
  color:gray;
  scale: 4 1 ;
  margin-left: 20px;
}
.active {
  color: black !important;
  font-weight: 700;
}
.completed {
  background-color: rgb(102, 174, 4) !important;
}
.completed v-icon {
  color: white !important;
}
.stepper {
  padding-bottom: 36px;
  background: transparent;
  border: none;
  box-shadow: none;
  align-self: center;
}
.step {
  padding: 24px 24px 16px;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
}
.step-number {
  background: transparent;
  width: 40px;
  height: 40px;
  color: #fff!important;
  font-size: 20px;
  line-height: 20px;
  padding-top: 3px;
  border: 1px solid #fff!important;
  margin-right: 12px;
}
.step-title {
  text-align: left;
  display: block;
  flex-grow: 1;
  line-height: 1;
}
.v-stepper__step__step {
align-items: center;
border-radius: 50%;
display: inline-flex;
justify-content: center;
min-width: 24px;
transition: .3s cubic-bezier(.25,.8,.25,1);
}

.number-active {
  border: 2px solid var(--accent-color) !important;
  color: var(--accent-color) !important;
}
.title-active{
  color: var(--accent-color) !important;
}
.circle-check {
  background: var(--accent-color);
  color: white !important;

}
</style>
