import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import { LTE_COMPANY_HASH, START_PAGE_FILE_TYPES } from '@/constants'

const routes = [
  {
    path: '/',
    redirect: { name: 'GeneralLogin' }
  },
  {
    path: '/:companyHash/setup/login',
    name: 'SetupLogin',
    component: () => import('../views/login/Login'),
    meta: {
      permitAll: true
    },
    props: {
      setupLogin: true
    }
  },
  {
    path: '/:companyHash/setup/password-reset',
    name: 'SetupPasswordReset',
    component: () => import('../views/signup/UpdatePassword'),
    meta: {
      permitAll: true
    },
    props: {
      setup: true
    }
  },
  {
    path: '/:companyHash/setup/branding',
    name: 'SetupBranding',
    component: () => import('../views/branding/BrandingSetup'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/setup/language',
    name: 'SetupLanguageReset',
    component: () => import('../views/languageTexts/SetupLanguageReset'),
    meta: {
      permitAll: true
    },
    props: {
      setup: true
    }
  },
  {
    path: '/:companyHash/setup/mfa',
    name: 'SetupMfa',
    component: () => import('../views/mfaSetup/MfaSetup'),
    meta: {
      permitAll: true
    },
    props: {
      setup: true
    }
  },
  {
    path: '/:companyHash/language-reset',
    name: 'LanguageReset',
    component: () => import('../views/languageTexts/SetupLanguageReset'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/mfa-setup',
    name: 'MfaSetup',
    component: () => import('../views/mfaSetup/MfaSetup'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/report/:companyHash',
    name: 'Report',
    component: () => import('../views/report/ReportDashboard'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/report/:companyHash/new',
    name: 'NewReport',
    component: () => import('../views/report/NewReport'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/access-report',
    name: 'AccessReport',
    component: () => import('../views/report/ReportDashboard'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/report/:companyHash/:reportNumber/pin',
    name: 'ViewReportPin',
    component: () => import('../views/report/NewReportPin'),
    props: route => ({
      newPinPage: false
    }),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/report/:companyHash/:reportNumber',
    name: 'ViewReportWHB',
    component: () => import('../views/reportDetails/ReportDetails'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/reports/',
    name: 'SMEReports',
    component: () => import('../views/reportOverview/Reports')
  },
  {
    path: '/:companyHash/users/',
    name: 'Users',
    component: () => import('../views/users/Users'),
    props: (route) => ({
      addNew: Boolean(route.query.addNew)
    })
  },
  {
    path: '/:companyHash/settings',
    name: 'SmeSettings',
    component: () => import('../views/accountSettings/AccountSettings'),
    props: {
      selectedComponent: 'SmeSettings'
    }
  },
  {
    path: '/:companyHash/settings/user',
    name: 'UserSettings',
    component: () => import('../views/accountSettings/AccountSettings'),
    props: {
      selectedComponent: 'UserSettings'
    }
  },
  {
    path: '/:companyHash/customizations',
    name: 'StartPageSettings',
    component: () => import('../views/customizationSettings/CustomizationSettings'),
    props: {
      selectedComponent: 'StartPageSettings'
    }
  },
  {
    path: '/:companyHash/customizations/questionnaire-templates',
    name: 'QuestionnaireSettings',
    component: () => import('../views/customizationSettings/CustomizationSettings'),
    props: {
      selectedComponent: 'QuestionnaireSettings'
    }
  },
  {
    path: '/:companyHash/settings/questionnaire-templates/:id',
    name: 'QuestionnaireTemplate',
    component: () => import('../views/questionnaireTemplates/QuestionnaireTemplate'),
    props: route => ({
      id: route.params.id,
      readOnly: route.query.readOnly ? (typeof route.query.readOnly === 'string' ? route.query.readOnly === 'true' : route.query.readOnly) : route.query.readOnly
    })
  },
  {
    path: '/:companyHash/customizations/categories',
    name: 'CategorySettings',
    component: () => import('../views/customizationSettings/CustomizationSettings'),
    props: {
      selectedComponent: 'CategorySettings'
    }
  },
  {
    path: '/:companyHash/customizations/notifications',
    name: 'NotificationSettings',
    component: () => import('../views/customizationSettings/CustomizationSettings'),
    props: {
      selectedComponent: 'NotificationSettings'
    }
  },
  {
    path: '/:companyHash/plan',
    name: 'PlanSettings',
    component: () => import('../views/planSettings/PlanSettings')
  },
  {
    path: '/:companyHash/organizations/',
    name: 'Organizations',
    component: () => import('../views/organizations/Organizations'),
    props: (route) => ({
      addNew: Boolean(route.query.addNew)
    })
  },
  {
    path: '/:companyHash/report/:reportId',
    name: 'ViewReportSME',
    component: () => import('../views/reportDetails/ReportDetails')
  },
  {
    path: '/:companyHash/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Dashboard')
  },
  {
    path: '/not-found',
    name: '404',
    component: () => import('../views/notFound/NotFound'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/login',
    name: 'Login',
    component: () => import('../views/login/Login'),
    meta: {
      permitAll: true
    },
    props: (route) => ({
      passwordResetPage: Boolean(route.query.passwordReset),
      sessionExpired: Boolean(route.query.sessionExpired)
    })
  },
  {
    path: '/:companyHash/password-reset',
    name: 'PasswordReset',
    component: () => import('../views/signup/UpdatePassword'),
    meta: {
      permitAll: true
    },
    props: {
      passwordResetPage: true
    }
  },
  {
    path: '/login',
    name: 'GeneralLogin',
    component: () => import('../views/login/Login'),
    meta: {
      permitAll: true
    },
    props: (route) => ({
      sessionExpired: Boolean(route.query.sessionExpired)
    })
  },
  {
    path: '/video/:companyHash',
    name: 'Video',
    component: () => import('../views/trainingVideo/TrainingVideo'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/signup/Signup'),
    meta: {
      permitAll: true
    },
    props: (route) => ({
      preselectedPricingPlan: route.query.pricingPlan
    })
  },
  {
    path: '/signup/plan',
    name: 'SignupPlan',
    component: () => import('../views/signup/SignupPlanSelectionView'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/signup/payment',
    name: 'SignupPayment',
    component: () => import('../views/signup/SignupPayment'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/signup/confirmation',
    name: 'SignupConfirmation',
    component: () => import('../views/signup/SignupConfirmation'),
    meta: {
      permitAll: true
    },
    props: (route) => ({
      organizationId: route.query.organizationId
    })
  },
  {
    path: '/:companyHash?/imprint',
    name: 'Imprint',
    component: () => import('../views/imprint/Imprint'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/custom-file/:id/:filename',
    name: 'StartPageCustomFile',
    component: () => import('../views/start-page-file/StartPageFile'),
    props: route => ({
      organizationId: route.params.companyHash,
      id: route.params.id,
      filename: route.params.filename,
      fileType: START_PAGE_FILE_TYPES.CUSTOM,
    }),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/legal-notice/:filename',
    name: 'CustomLegalNotice',
    component: () => import('../views/start-page-file/StartPageFile'),
    props: route => ({
      organizationId: route.params.companyHash,
      filename: route.params.filename,
      fileType: START_PAGE_FILE_TYPES.LEGAL_NOTICE
    }),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash?/data-protection',
    name: 'DataProtection',
    component: () => import('../views/dataProtection/DataProtection'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/data-protection/:filename',
    name: 'CustomDataProtection',
    component: () => import('../views/start-page-file/StartPageFile'),
    props: route => ({
      organizationId: route.params.companyHash,
      filename: route.params.filename,
      fileType: START_PAGE_FILE_TYPES.DATA_PROTECTION
    }),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash?/accessibility-declaration',
    name: 'AccessibilityDeclaration',
    component: () => import('../views/accessibilityDeclaration/AccessibilityDeclaration.vue'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/accessibility-declaration/:filename',
    name: 'CustomAccessibilityDeclaration',
    component: () => import('../views/start-page-file/StartPageFile'),
    props: route => ({
      organizationId: route.params.companyHash,
      filename: route.params.filename,
      fileType: START_PAGE_FILE_TYPES.ACCESSIBILITY_DECLARATION
    }),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/FAQ',
    name: 'Faq',
    component: () => import('../views/faq/Faq'),
    meta: {
      permitAll: true
    }
  },
  {
    path: '/:companyHash/faq',
    name: 'CustomFaq',
    component: () => import('../views/start-page-file/StartPageFile'),
    props: route => ({
      organizationId: route.params.companyHash,
      filename: route.params.filename,
      fileType: START_PAGE_FILE_TYPES.FAQ,
    }),
    meta: {
      permitAll: true
    }
  },
  {
    path: `/${LTE_COMPANY_HASH}/language-texts`,
    name: 'LanguageTexts',
    component: () => import('../views/languageTexts/LanguageTexts'),
    beforeEnter: (to, from, next) => {
      to.params.companyHash = LTE_COMPANY_HASH
      next()
    }
  },
  {
    path: `/${LTE_COMPANY_HASH}/translation-editor`,
    name: 'TranslationEditor',
    component: () => import('../views/translationEditor/TranslationTable'),
    beforeEnter: (to, fromt, next) => {
      to.params.companyHash = LTE_COMPANY_HASH
      next()
    }
  },
  {
    path: '/:companyHash',
    redirect: { name: 'Login' }
  },
  {
    path: '/**',
    name: 'Default404',
    component: () => import('../views/login/Login'),
    meta: {
      permitAll: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  if (process.env.JEST_WORKER_ID) {
    return
  }

  window.scroll(0, 0)
  if (to.params.reportNumber) {
    store.commit('setCurrentReportNumber', to.params.reportNumber)
  }

  if (store) {
    const companyHash = to.params.companyHash || null
    store.commit('setCurrentOrganizationId', companyHash)
    await store.dispatch('updateColorCodes')
  }

  next()
})

export default router
