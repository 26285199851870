import dayjs from "@/plugins/day"

export default {

  setLanguages (state, languages) {
    state.languages = reorderLanguages(languages)
  },

  setLanguagesWithTranslationTexts (state, languagesWithTranslationTexts) {
    for (const language of languagesWithTranslationTexts) {
      language.language_texts.forEach(text => {
        text.loadedUpdated = text.updated
        text.updated = dayjs(text.updated)
      })
    }

    state.languagesWithTranslationTexts = reorderLanguages(languagesWithTranslationTexts)
  },

  setLanguage (state, language) {
    state.language = language
  },

  setLanguagesSubscription (state, subscription) {
    state.languagesSubscription = subscription
  },

  updateLanguageTexts (state, languages) {
    for (const language of languages) {
      const languageFromStore = state.languagesWithTranslationTexts.find(lang => lang.locale === language.locale)
      if (languageFromStore) {
        const textsMap = {}
        language.language_texts.forEach(text => {
          text.loadedUpdated = text.updated
          text.updated = dayjs(text.updated)
          textsMap[text.key] = text
        })
        languageFromStore.language_texts = languageFromStore.language_texts.map(languageText => {
          const found = textsMap[languageText.key]
          if (found) {
            delete textsMap[languageText.key]
          }
          return found || languageText
        })

        languageFromStore.language_texts.push(...Object.values(textsMap))
      }
    }
  },

  setLanguageKeys (state, keys) {
    state.languageKeys = keys.map(key => key.key)
  },

  setForcedLanguages (state, languages) {
    state.forcedLanguages = languages
  },

  addLoadedLanguageKey (state, key) {
    if (!state.loadedLanguagesKeys.includes(key)) {
      state.loadedLanguagesKeys.push(key)
    }
  }
}

const reorderLanguages = (languages) => {
  // re-order languages such that de and en are always at first two position
  const langPriorityOrder = ['de', 'en']

  if (langPriorityOrder.some((slug, i) => languages[i].locale !== slug)) {
    languages = languages.sort((langA, langB) => {
      let langAPriority = langPriorityOrder.indexOf(langA.locale)
      let langBPriority = langPriorityOrder.indexOf(langB.locale)

      if (langAPriority === -1) {
        langAPriority = langPriorityOrder.length
      }
      if (langBPriority === -1) {
        langBPriority = langPriorityOrder.length
      }

      return langAPriority - langBPriority
    })
  }
  return languages
}
