export default {
  languagesWithTextMaps (state) {
    return state.languagesWithTranslationTexts.map(lang => {
      return {
        ...lang,
        languageTextMap: lang.language_texts.reduce((acc, cur) => {
          acc[cur.key] = cur
          return acc
        }, {})
      }
    })
  },
  languagesSorted: (state, getters) => (languages) => {
    return languages.sort((a, b) => {
      if (a.slug === 'de' || b.slug === 'de') {
        return a.slug === 'de' ? -1 : 1
      }
      if (a.slug === 'en' || b.slug === 'en') {
        return a.slug === 'en' ? -1 : 1
      }
      return a.slug > b.slug ? 1 : -1
    })
  },

  isLoadedLanguageByKey: (state) => (key) => {
    return !!state.loadedLanguagesKeys.filter((languageKey) => languageKey === key).length
  }
}
