import { createVuetify } from 'vuetify'

import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'
import fr from 'vuetify/lib/locale/fr'
import es from 'vuetify/lib/locale/es'
import it from 'vuetify/lib/locale/it'
import pl from 'vuetify/lib/locale/pl'
import nl from 'vuetify/lib/locale/nl'
import no from 'vuetify/lib/locale/no'
import pt from 'vuetify/lib/locale/pt'
import el from 'vuetify/lib/locale/el'
import sk from 'vuetify/lib/locale/sk'
import cs from 'vuetify/lib/locale/cs'
import hu from 'vuetify/lib/locale/hu'
import sl from 'vuetify/lib/locale/sl'
import sv from 'vuetify/lib/locale/sv'
import ro from 'vuetify/lib/locale/ro'
import sr from 'vuetify/lib/locale/sr-Cyrl'
import tr from 'vuetify/lib/locale/tr'
import zh from 'vuetify/lib/locale/zh-Hans'
import ar from 'vuetify/lib/locale/ar'
import fa from 'vuetify/lib/locale/fa'
import fi from 'vuetify/lib/locale/fi'
import ja from 'vuetify/lib/locale/ja'
import ru from 'vuetify/lib/locale/ru'
import ko from 'vuetify/lib/locale/ko'
import id from 'vuetify/lib/locale/id'
import hr from 'vuetify/lib/locale/hr'
import { ACCENT_COLOR_CODE, PRIMARY_COLOR_CODE, ERROR_COLOR_CODE } from '@/constants'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md, aliases } from 'vuetify/iconsets/md'


const bg = en
const da = en
const hi = en

const vuetify = createVuetify({
  options: {
    customProperties: true
  },
  display:{
    mobileBreakpoint: 700
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        colors: {
          primary: PRIMARY_COLOR_CODE,
          secondary: '#3F4242',
          accent: ACCENT_COLOR_CODE,
          error: ERROR_COLOR_CODE,
          info: '#2196F3',
          success: '#63C63A',
          warning: '#EC6610',
          caution: '#E7B60C'
        }
      }
    }
  },
  locale: {
    locales: { de, en, fr, es, it, pl, nl, no, pt, el, sk, cs, bg, da, hi, sv, hu, sl, ro, sr, tr, zh, ar, fa, fi, ja, ko, ru, id, hr },
    current: 'en'
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md
    }
  },
  components: { ...components },
  directives
})

export default vuetify;
