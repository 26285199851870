<template>
  <v-navigation-drawer
    :model-value="true"
    @update:modelValue="v => $emit('update:modelValue', v)"
    absolute
    temporary
  >
    <v-list density="compact">
      <v-list-item link v-if="$route.name === 'ViewReportWHB'" @click="logout()">
        <div class="list-item">
          <div class="list-item-icon">
          <v-icon>logout</v-icon>
          </div>

          <div class="list-item-content">
          <v-list-item-title>{{ $t('general.logoutTooltip') }}</v-list-item-title>
          </div>
        </div>
      </v-list-item>

      <v-list-item link v-if="$route.name === 'ViewReportWHB'" @click="downloadReport()">
        <div class="list-item">
          <div class="list-item-icon">
          <v-icon>save_alt</v-icon>
        </div>

          <div class="list-item-content">
          <v-list-item-title>{{ $t(`reportDetails.smeHeading.downloadReport`) }}</v-list-item-title>
          </div>
        </div>
      </v-list-item>

      <v-list-item>
        <div class="list-item">
          <div class="list-item-content">
          <LanguageSelection @update:modelValue="ev => changeUserLanguage({language: ev, save: false})" />
          </div>
        </div>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import LanguageSelection from './LanguageSelection'
import { mapActions } from 'vuex'
export default {
  name: 'MobileWhbNavigation',
  components: { LanguageSelection },
  emits: ['update:modelValue'],
  methods: {
    ...mapActions(['changeUserLanguage', 'logout']),

    downloadReport () {
      this.$emit('downloadReport')
    }
  }
}
</script>
