import dayjs from "@/plugins/day"
import i18next from 'i18next'
export default {
  currentReportPending (state) {
    return state.currentReport?.status === 'PENDING'
  },

  currentReportClosed (state) {
    return state.currentReport?.status === 'CLOSED'
  },

  currentReportArchived (state) {
    return state.currentReport?.status === 'ARCHIVED'
  },

  currentReportIsManuallyDeleted (state) {
    return state.currentReport && state.currentReport.status === 'MANUALLY_DELETED'
  },

  currentReportInReview (state) {
    return state.currentReport?.status === 'REVIEW'
  },

  currentReportInProgress (state) {
    return state.currentReport?.status === 'IN_PROGRESS'
  },

  dateFilterSorted (state) {
    return state.reportsTableOptions.filter.date.slice().sort((a, b) => {
      const aMoment = dayjs(a, 'YYYY-MM-DD')
      const bMoment = dayjs(b, 'YYYY-MM-DD')
      return aMoment.isBefore(bMoment) ? -1 : 1
    })
  },

  currentReportOrganizationDisabled (state, getters) {
    if (!state.currentReport || getters.isWhb) {
      return false
    }

    const organizationId = state.currentReport.organization_id
    const organization = state.organizations.find(org => org.id === organizationId)
    return !organization?.active
  },

  currentReportOrganizationSuspended (state, getters) {
    if (!state.currentReport || getters.isWhb) {
      return false
    }

    const organizationId = state.currentReport.organization_id
    const organization = state.organizations.find(org => org.id === organizationId)
    return organization.suspended
  },

  questions (state, getters) {
    if (getters.isCallOperator) {
      return getters.newReportQuestionnaireQuestions.filter(question => question.type !== 'FILE_UPLOAD')
    } else {
      return getters.newReportQuestionnaireQuestions
    }
  },

  newReportQuestionnaireQuestions (state, getters) {
    if (state.reportStatement.category) {
      const category = state.categories.find(category => category.organization_id === getters.ownOrganization.id && category.id === state.reportStatement.category)
      const questions = category ? JSON.parse(JSON.stringify(category.questionnaire.questions)) : [] // force hard copy
      return questions
    }

    return []
  },

  booleanOptions (state) {
    return [
      {
        id: 'YES',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('yes', { lng: cur.locale })
          return acc
        }, {})
      },
      {
        id: 'NO',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('no', { lng: cur.locale })
          return acc
        }, {})
      }
    ]
  },

  personalInformationOptions (state) {
    return [
      {
        id: 'NAMED',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.options.named', { lng: cur.locale })
          return acc
        }, {})
      },
      {
        id: 'ANONYMOUS',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.options.anonymous', { lng: cur.locale })
          return acc
        }, {})
      }
    ]
  },

  personalInformationSubFields (state) {
    return {
      first_name: {
        key: 'firstName',
        mandatory: true,
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.firstName', { lng: cur.locale })
          return acc
        }, {})
      },
      last_name: {
        key: 'lastName',
        mandatory: true,
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.lastName', { lng: cur.locale })
          return acc
        }, {})
      },
      country: {
        key: 'country',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.country', { lng: cur.locale })
          return acc
        }, {})
      },
      job_title: {
        key: 'jobTitle',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.jobTitle', { lng: cur.locale })
          return acc
        }, {})
      },
      phone: {
        key: 'phone',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.phone', { lng: cur.locale })
          return acc
        }, {})
      },
      email: {
        key: 'email',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.email', { lng: cur.locale })
          return acc
        }, {})
      },
      confirm_email: {
        key: 'confirmEmail',
        label_translations: state.languages.reduce((acc, cur) => {
          acc[cur.locale] = i18next.t('standardQuestionnaire.personalInformation.subField.confirmEmail', { lng: cur.locale })
          return acc
        }, {})
      },
    }
  },

  personalInformationSubFieldKeys (state, getters) {
    const result = []
    const personalInformationSubFields = getters.personalInformationSubFields
    Object.keys(personalInformationSubFields).forEach(field => result.push(personalInformationSubFields[field].key))
    return result
  }
}
