<template>
  <v-list v-if="canNavigateNotifications" :dense='!miniVariant'
          :style='!miniVariant?"padding-top:0px;margin-top:-1px; padding-bottom:0px":""'
          key="notifications">
    <v-menu content-class="notification-menu" offset-y>
      <template v-slot:activator="{ props }">
        <v-tooltip location="right" :disabled='!miniVariant'>
          <template v-slot:activator="{ props: tooltipProps }">
            <v-list-item
              title=""
              class="list-item-with-overflow"
              v-bind="{...props, ...tooltipProps}">
              <div class="list-item">
                <div class="list-item-icon">
                  <v-badge
                    :overlap='miniVariant'
                    :content="unreadNotifications"
                    color="error"
                    v-if="unreadNotifications && !isNaN(unreadNotifications)"
                  >
                    <v-icon>notifications</v-icon>
                  </v-badge>
                  <v-icon v-else>notifications</v-icon>
                </div>
                <div class="list-item-content">
                  {{ $t('navigation.notifications') }}
                </div>
                <div class="list-item-action">
                  <v-btn
                    icon
                    density="compact"
                    variant="flat">
                    <v-icon>keyboard_arrow_right</v-icon>
                  </v-btn>
                </div>

              </div>
            </v-list-item>
          </template>
          <div class="list-item-content">
            {{ $t('navigation.notifications') }}
          </div>
        </v-tooltip>
      </template>
      <v-list v-if="notifications.length">
        <v-list-item
          title=""
          :key="index"
          @click="item.report ? openReport(item.report.id): handlePlanExpiresNotification(item)"
          v-for="(item, index) in notifications"
        >
          <template v-slot:prepend>
            <span class="dot" :class="{unread: !item.read}"></span>
          </template>
          <v-list-item-title>
            {{
              (!item.report || (item.report.organization.id === userData.organization_id)) ? $t('navigation.notificationsOwnAccount') : item.report.organization.name
            }}
          </v-list-item-title>
          <!-- we need the strange { report: { caseId ... } } translation syntax because the tag we are replacing has a syntax of {{ report.caseId }} -->
          <v-list-item-subtitle class="text--primary"
                                style="max-width: 450px; white-space: normal; opacity: unset; display: block;">
            {{
              $t(`messages.${item.template_key}.notificationText`, { report: { caseId: `#${item.report ? item.report.case_id : null}` } })
            }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-text="formatTimestampLong(item.created)"></v-list-item-subtitle>
        </v-list-item>
        <v-list-item
          @click.stop="loadMoreNotifications()"
          v-if="notificationLength > notifications.length">
          <template>

            <v-list-item-title>{{ $t('navigation.loadMoreNotifications') }}</v-list-item-title>

          </template>
        </v-list-item>
      </v-list>
      <v-list v-else>
        <v-list-item>

          <v-list-item-title>{{ $t('navigation.noNotifications') }}</v-list-item-title>

        </v-list-item>
      </v-list>
    </v-menu>
  </v-list>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'NavigationItemNotification',
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant,
      userData: state => state.userData,
      currentReport: state => state.currentReport,
      notifications: state => state.notifications,
      unreadNotifications: state => state.unreadNotifications,
      notificationLength: state => state.notificationLength
    }),
    ...mapGetters([
      'canNavigateNotifications',
      'formatTimestampLong'
    ])
  },
  methods: {
    ...mapActions([
      'loadMoreNotifications',
      'markReportAsRead',
      'markPlanExpiryNotificationAsRead'
    ]),
    openReport (reportId) {
      if (this.$route.name === 'ViewReportSME' && this.currentReport && this.currentReport.id === reportId) {
        // if the user selects a notification for a report that is currently opened, the view will not be rendered so we just mark the rport as read (so the notification will also be marked as read)
        this.markReportAsRead()
      } else {
        this.$router.push({ name: 'ViewReportSME', params: { reportId: reportId } })
      }
    },

    handlePlanExpiresNotification (notification) {
      notification.read = true
      this.markPlanExpiryNotificationAsRead(notification)
      if (this.$route.name !== 'PlanSettings') {
        this.$router.push({ name: 'PlanSettings' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.list-item-with-overflow :deep(.v-list-item__content) {
  overflow: visible !important;
}

:deep(.v-list-item-title) {
  font-size:   14px !important;
  font-weight: bold !important;
}

:deep(.v-badge__badge) {
  bottom:    calc(100% - 15px) !important;
  font-size: 0.60rem;
  padding:   0;
  height:    1rem;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px;
}

.dot {
  height:           10px;
  width:            10px;
  background-color: white;
  border-radius:    50%;
  display:          inline-block;
  margin-right:     6px;
}

.unread {
  background-color: #c63a3a;
}

:deep(.v-overlay__content) {
  max-height: 90% !important;
}
</style>

<style lang="scss" scoped>

.notification-menu {
  max-height: 500px;
  background: #fff;
  padding:    2px;
}

.notification-menu .v-list-item {
  padding-left: 20px;
  max-width:    500px;
}

.pointer:hover {
  cursor: pointer;
}

.list-item-action {
  margin-inline-start: auto;
}
</style>
