/**
 *     A plan that has this string in its name will be treated as an individual plan (case insensitive)
 */
const INDIVIDUAL_PLAN_NAME_MARKER = 'individual'

const SALES_PARTNER_PLAN_NAME_MARKER = 'sales'

const SUBSIDIARY_PLAN_NAME_MARKER = 'affiliate'

/**
 *     A plan variant that has this string in its name will be treated as the variant for law firm managed SMEs (case insensitive)
 */
const LAWFIRM_MANAGED_VARIANT_NAME_MARKER = 'white label'
const LAWFIRM_MANAGED_VARIANT_NAME_MARKER_LEGACY = 'law firm managed'

export default {

  getPricingPlanById: (state) => (pricingPlanId) => {
    return state.pricingPlans.find(plan => plan.Id === pricingPlanId)
  },

  selfSignupPricingPlans (state) {
    return state.pricingPlanTemplates.filter(plan =>
      !plan.name.toLowerCase().includes(INDIVIDUAL_PLAN_NAME_MARKER) &&
      !plan.name.toLowerCase().includes(SUBSIDIARY_PLAN_NAME_MARKER) &&
      !plan.plan_variant_name.toLowerCase().includes(LAWFIRM_MANAGED_VARIANT_NAME_MARKER) &&
      !plan.plan_variant_name.toLowerCase().includes(LAWFIRM_MANAGED_VARIANT_NAME_MARKER_LEGACY) &&
      plan.recurring_fee !== 0)
  },

  lawFirmManagedPricingPlans (state) {
    return state.pricingPlanTemplates.filter(plan =>
      !plan.name.toLowerCase().includes(SUBSIDIARY_PLAN_NAME_MARKER) &&
      (plan.plan_variant_name.toLowerCase().includes(LAWFIRM_MANAGED_VARIANT_NAME_MARKER) ||
      plan.plan_variant_name.toLowerCase().includes(LAWFIRM_MANAGED_VARIANT_NAME_MARKER_LEGACY)))
  },

  individualPricingPlans (state) {
    return state.pricingPlanTemplates.filter(plan => plan.name.toLowerCase().includes(INDIVIDUAL_PLAN_NAME_MARKER))
  },

  salesPartnerPricingPlans (state) {
    return state.pricingPlanTemplates.filter(plan => plan.name.toLowerCase().includes(SALES_PARTNER_PLAN_NAME_MARKER))
  },

  subsidiaryPlanWhiteLabel (state) {
    return state.pricingPlanTemplates.find(plan => plan.plan_variant_name.toLowerCase() === 'affiliate (white label)')
  },

  subsidiaryPlanVanilla (state) {
    return state.pricingPlanTemplates.find(plan => plan.plan_variant_name.toLowerCase() === 'affiliate')
  },

  hidePrice (state, getters) {
    return !getters.isRootOrganization
  }

}
