import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  async loadQuestionnaireTemplates ({ state, getters, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query questionnaire {
          questionnaire(where: {is_active: {_eq: true}}) {
            id
            template_id
            name
            questions
            organization {
              ${getters.isCallOperator ? '' : 'dtype'}
              name
              id
            }
            report_categories(where: {is_active: {_eq: true}}) {
              label_translations
              updated
              id
              organization_id
            }
          }
        }
      `
    })

    _adjustStandardQuestionnaireReportCategories(response.data.questionnaire, state.categories)

    commit('setQuestionnaireTemplates', response.data.questionnaire)
  },

  async loadQuestionnaireTemplate ({ state, commit }, id) {
    const response = await apollo(state).query({
      query: gql`
        query questionnaire ($id: uuid) {
          questionnaire(where: {id: {_eq: $id}}) {
            id
            template_id
            name
            questions
            is_active
            organization {
              dtype
              name
              id
            }
            report_categories {
              label_translations
              updated
              id
            }
          }
        }
      `,
      variables: {
        id
      }
    })

    return response.data.questionnaire[0]
  },

  async saveQuestionnaireTemplate ({ dispatch, state }, { questionnaire, activate }) {
    const body = JSON.parse(JSON.stringify(questionnaire))

    for (const question of body.questions) {
      question.options.pop()
    }
    return await dispatch('POST', {
      url: `/rest/questionnaire/save-questionnaire?activate=${activate}&organizationId=${questionnaire.organization.id}`,
      body: JSON.stringify(body)
    })
  },
  async deleteQuestionnaireTemplate ({ dispatch, state }, questionnaireTemplateId) {
    await dispatch('GET', {
      url: `/rest/questionnaire/delete-questionnaire-template?questionnaireTemplateId=${questionnaireTemplateId}`
    })
  },
  async createQuestionnaireTemplate ({ dispatch, state }, { name, organizationId, templateBlueprintId }) {
    return await dispatch('POST', {
      url: `/rest/questionnaire/create-questionnaire-template?name=${name}&organizationId=${organizationId}&templateBlueprintId=${templateBlueprintId}`
    })
  },

  async setCategoryActive ({ dispatch, state }, category) {
    await dispatch('POST', {
      url: `/rest/category/set-category-active?categoryId=${category.id}&organizationId=${category.organization_id}`
    })
  },

  async saveCategory ({ dispatch, state }, category) {
    const body = { ...category }
    const newQuestionnaireName = body.newQuestionnaireName
    const newQuestionnaireBlueprintId = body.newQuestionnaireBlueprintId || ''

    delete body.organization
    delete body.newQuestionnaireName
    delete body.newQuestionnaireBlueprintId

    let createQuestionnaire = false
    if (category.questionnaire && category.questionnaire.id === 'ADD_QUESTIONNAIRE') {
      createQuestionnaire = true
      body.questionnaire = null
    }

    return await dispatch('POST', {
      url: `/rest/category/save-category?organizationId=${category.organization_id}&createQuestionnaire=${createQuestionnaire}&newQuestionnaireName=${newQuestionnaireName}&newQuestionnaireBlueprintId=${newQuestionnaireBlueprintId}`,
      body: JSON.stringify(body)
    })
  },

  async importCategories ({ dispatch, getters }, { startOrganizationId, targetOrganizationId }) {
    await dispatch('POST', {
      url: `/rest/category/import-categories?fromOrganizationId=${startOrganizationId}&toOrganizationId=${targetOrganizationId}`
    })
  },

  async moveCategory ({ dispatch }, { category, moveUp }) {
    await dispatch('POST', {
      url: `/rest/category/move-category?categoryId=${category.id}&organizationId=${category.organization_id}&moveUp=${moveUp}`
    })
  },

  async loadCategories ({ state, commit, dispatch, getters }) {
    if (getters.isTranslator) {
      console.warn('Translators should not attempt loading categories')
      return
    }
    const response = await apollo(state).query({
      query: gql`
        query categories {
          report_category {
            id
            label_translations
            display_order
            is_active
            law_type
            questionnaire {
              questions
              id
              name
            }
            organization_id
            organization {
              name
            }
            updated
          }
          organization {
            id
            name
            standard_category_metadata
            parent_id
          }
          questionnaire {
            questions
            id
            name
          }
        }
      `
    })

    // Remove parent organizations
    if (state.currentOrganizationId || state.userData) {
      // eslint-disable-next-line camelcase
      const currentOrgId = state.currentOrganizationId || state.userData?.organization_id
      // eslint-disable-next-line camelcase
      let parentId = response.data.organization.find(organization => organization.id === currentOrgId)?.parent_id
      let parentIndex = response.data.organization.findIndex(organization => organization.id === parentId)
      while (parentIndex !== -1) {
        parentId = response.data.organization[parentIndex].parent_id
        response.data.organization.splice(parentIndex, 1)
        parentIndex = response.data.organization.findIndex(organization => organization.id === parentId)
      }
    }

    const categories = _createStandardCategoriesFromMetadata(response.data.report_category, response.data.organization, response.data.questionnaire)
    if (!getters.isLteAdmin || !getters.isTranslator) {
      commit('setCategories', categories)
    }
  },

  async subscribeToCategories ({ state, commit, getters }) {
    if (state.categorySubscription) {
      state.categorySubscription.unsubscribe()
    }

    const observer = apollo(state).subscribe({
      query: gql`
        subscription report_category {
          report_category {
            id
            label_translations
            display_order
            is_active
            law_type
            questionnaire {
              questions
              id
              name
            }
            organization_id
            organization {
              name
            }
            updated
          }
        }
      `
    })

    const subscription = observer.subscribe({
      next (data) {
        const categoriesFromMetadata = state.categories.filter(category => category.isStandardCategory)
        commit('setCategories', [...data.data.report_category, ...categoriesFromMetadata])
      },
      error (error) {
        console.error('subscription error', error)
      }
    })

    commit('setCategorySubscription', subscription)
  },

  async subscribeToCategoryMetadata ({ state, commit, getters }) {
    if (state.categoryMetadataSubscription) {
      state.categoryMetadataSubscription.unsubscribe()
    }

    const observer = apollo(state).subscribe({
      query: gql`
        subscription report_category {
          organization {
            id
            name
            standard_category_metadata
          }
        }
      `
    })

    const subscription = observer.subscribe({
      next (data) {
        // we need to rebuild the standard categories from the new metadata, so we must not pass the categories previously created from metadata
        const categories = _createStandardCategoriesFromMetadata(state.categories.filter(category => !category.isStandardCategory), data.data.organization, state.questionnaireTemplates)
        commit('setCategories', categories)
      },
      error (error) {
        console.error('subscription error', error)
      }
    })

    commit('setCategoryMetadataSubscription', subscription)
  },

  async deleteCategory ({ dispatch }, { categoryId, organizationId }) {
    return await dispatch('POST', {
      url: `/rest/category/delete-category?categoryId=${categoryId}&organizationId=${organizationId}`,
      handleFailure: false
    })
  },

  async loadQuestionnaireTemplateVersions ({ state, commit }, templateId) {
    const response = await apollo(state).query({
      query: gql`
        query questionnaire ($templateId: uuid) {
          questionnaire(where: {template_id: {_eq: $templateId}}, order_by: {created: desc}) {
            id
            template_id
            name
            created
            is_active
          }
        }
      `,
      variables: {
        templateId
      }
    })

    commit('setQuestionnaireTemplateVersions', response.data.questionnaire)
  },

  async activateQuestionnaireVersion ({ dispatch }, id) {
    return await dispatch('POST', {
      url: `/rest/questionnaire/activate-questionnaire?questionnaireId=${id}`
    })
  }
}

export function assignStandardCategoriesToOrganizationsFromMetadata (standardCategories, organizations, questionnaires) {
  const allCategories = _createStandardCategoriesFromMetadata(standardCategories, organizations, questionnaires)
  const organizationsById = {}
  for (const organization of organizations) {
    organizationsById[organization.id] = organization
  }
  for (const category of allCategories) {
    if (category.organization_id && organizationsById[category.organization_id]) {
      organizationsById[category.organization_id].categories.push(category)
    }
  }
}

// eslint-disable-next-line camelcase
function _createStandardCategoriesFromMetadata (report_categories, organizations, questionnaires) {
  // eslint-disable-next-line camelcase
  const categories = [...report_categories]
  // need to create the standard category versions for each organization based on the metadata
  const standardCategories = report_categories.filter(category => category.organization_id === null)
  for (const organization of organizations) {
    for (const standardCategory of standardCategories) {
      const metadata = organization.standard_category_metadata?.[standardCategory.id]
      if (metadata) {
        const organizationStandardCategory = JSON.parse(JSON.stringify(standardCategory))
        organizationStandardCategory.display_order = parseInt(metadata.display_order, 10)
        organizationStandardCategory.is_active = metadata.is_active
        organizationStandardCategory.questionnaire = questionnaires.find(questionnaire => questionnaire.id === metadata.questionnaire_id)
        organizationStandardCategory.organization = { name: organization.name }
        organizationStandardCategory.organization_id = organization.id
        organizationStandardCategory.id = standardCategory.id

        organizationStandardCategory.isStandardCategory = true

        categories.push(organizationStandardCategory)
      }
    }
  }
  return categories
}

/**
 * Only single instances of the standard questionnaires and categories exist in the database
 * however, each organization behaves as if they had their own local copy of these entities.
 * We must reflect this reality in the state.
 *
 * Example: Three organizations have a standard category "supply chain" using the standard supply chain questionnaire
 * When we load the questionnaires from the state and we look at the field questionnaire.report_categories, we will not
 * find three categories, as we might expect (one for each organization), but rather just one because "supply chain" is a
 * standard category. This function will duplicates the standard category three times and associate it with each different
 * organization
 *
 * @param questionnaires: the set of questionnaires returned by Hasura
 * @param categories: the set of all cateogories, FROM THE STATE
 */
function _adjustStandardQuestionnaireReportCategories (questionnaires, categories) {
  const standardCategories = categories.filter(category => category.isStandardCategory);
  const standardCategoryHelpers = standardCategories
    .filter(category => category.questionnaire)
    .map(category => ({
      category_id: category.id,
      questionnaire_id: category.questionnaire.id,
      organization_id: category.organization_id,
      is_active: category.is_active
    }));
  // Store distinct collection of standard categoryIds
  const standardCategoryIds = [ ...new Set(standardCategories.map(category => category.id))];


  const standardQuestionnaires = questionnaires.filter(item => item.organization === null)
  // we need to store the "format" of the values found in questionnaire.report_categories
  const standardCategoriesFromQuestionnaires = {}
  for (const questionnaire of standardQuestionnaires) {
    questionnaire.report_categories
      .filter(category => standardCategoryIds.includes(category.id))
      .forEach(category => { standardCategoriesFromQuestionnaires[category.id] = category });
    // we then have to remove the standard categories because we will be filling that data later on
    questionnaire.report_categories = questionnaire.report_categories.filter(category => !standardCategoryIds.includes(category.id))
  }

  for (const standardCategoryHelper of standardCategoryHelpers) {
    if (standardCategoryHelper.is_active) {
      const questionnaire = questionnaires.find(questionnaire => questionnaire.id === standardCategoryHelper.questionnaire_id)
      if (questionnaire) {
        // Copy the standard category object that was present in the questionnaire, then enrich the data
        const reportCategory = Object.assign({}, standardCategoriesFromQuestionnaires[standardCategoryHelper.category_id])
        reportCategory.organization_id = standardCategoryHelper.organization_id
        questionnaire.report_categories.push(reportCategory)
      }
    }
  }
}
