export default {
  setOrganizations (state, organizations) {
    state.organizations = organizations
  },

  setParentOrganizations (state, parentOrganizations) {
    state.parentOrganizations = parentOrganizations
  },

  setOwnAndChildOrganizations (state, ownAndChildOrganizations) {
    state.ownAndChildOrganizations = ownAndChildOrganizations
  },

  setOrganizationConfiguration (state, organizationConfiguration) {
    state.organizationConfiguration = organizationConfiguration
  },

  setLawfirms (state, lawfirms) {
    state.lawfirms = lawfirms
  },

  setFilteredOrganizations (state, {data, totalCount}) {
    state.filteredOrganizations = {data, totalCount}
  },

  setCurrentOrganizationId (state, currentOrganizationId) {
    state.currentOrganizationId = currentOrganizationId
  },

  setAccounts (state, organizationIds) {
    state.accountsFilter = organizationIds
  },

  setShow404Page (state, show) {
    state.show404Page = show
  },

  setPricingPlanExpires (state, pricingPlanExpires) {
    state.pricingPlanExpires = pricingPlanExpires
  },

  setRoles (state, roles) {
    state.roles = roles
  }

}
