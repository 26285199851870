export default {

  setPricingPlanTemplates (state, pricingPlans) {
    state.pricingPlanTemplates = pricingPlans
  },

  setPricingPlans (state, pricingPlans) {
    state.pricingPlans = pricingPlans
  },

  setPlanExpires (state, show) {
    state.planExpires = show
  }

}
