<template>
  <div>
    <v-list-item key="Logo" v-if='!miniVariant' style='border-bottom: 1px solid var(--border-gray); height: 67px;' title="">
      <div v-if="!isMiniVariant" style="align-items: center;display: flex">
        <v-img
          v-if="isGlobalUser"
          src="/legaltegrity-logo.png"
          :width="172"
          contain max-height="42" position="left"
        />
        <v-img
          v-else
          :width="172"
          :src="`/organization/${userData.organization_id}/logo${logoFileName}`"
          contain max-height="42" position="left"
        />
      </div>

      <v-tooltip right style="margin-left:16px">
        <template v-slot:activator="{ props }">
          <v-list-item-action @click="$emit('setMiniVariant', true)" v-bind="props">
            <v-btn icon>
              <v-icon style='transform:rotate(-180deg);font-size: 24px'>double_arrow</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
        <label>{{ $t(`navigation.expandCollapse.collapse`) }}</label>
      </v-tooltip>
    </v-list-item>

    <v-tooltip v-if='miniVariant' right key='expandBtn'>
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props" @click="$emit('setMiniVariant', false)" link style='border-bottom: 1px solid var(--border-gray);'  title="">
          <v-list-item-action style='height:67px'>
            <v-icon>double_arrow</v-icon>
          </v-list-item-action>
        </v-list-item>
      </template>
      <label>{{ $t(`navigation.expandCollapse.expand`) }}</label>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'NavigationHeader',
  props: {
    isMiniVariant: {
      type: Boolean
    }
  },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant,
      userData: state => state.userData,
      ownAndChildOrganizations: state => state.ownAndChildOrganizations
    }),
    ...mapGetters([
      'isGlobalUser'
    ]),
    logoFileName () {
      // eslint-disable-next-line camelcase
      const filename = this.ownAndChildOrganizations.find(organization => organization.id === this.userData.organization_id)?.logo_filename || 'logo'
      return `_${filename}`
    }
  }
}
</script>

<style scoped>
:deep(.v-list-item__content){
  align-items: center;
  display: flex;
}
:deep(.v-list-item-action){
  margin-inline-start:auto;
}
</style>
