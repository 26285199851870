import gql from 'graphql-tag'
import apollo from '../../apollo'
import { chatMessageFields } from '../graphqlFragments'

export default {

  async addChatMessage ({ dispatch, state }, message) {
    return await dispatch('POST', {
      url: `/rest/report/${state.currentReport.id}/add-chat-message`,
      body: message
    })
  },

  async subscribeToChatMessages ({ dispatch, state, commit }) {
    if (state.chatSubscription) {
      state.chatSubscription.unsubscribe()
    }

    const observer = apollo(state).subscribe({
      query: gql`
        subscription chat_message($reportId: uuid) {
          chat_message(where: {report_id: {_eq: $reportId}}) {
            ${chatMessageFields(state.userData)}
          }
        }
      `,
      variables: {
        reportId: state.currentReportId
      }
    })
      setTimeout(()=>{
        if(state.isChatSubscriptionFunctional == null )
          commit('setIsChatSubscriptionFunctional',false)},3000)

      const subscription = observer.subscribe({
        next(data) {
          commit('setIsChatSubscriptionFunctional',true)
          if (!state.currentReport) {
            return
          }
          const invalidId = data.data.chat_message.some(chatMessage => chatMessage.report_id !== state.currentReportId)

          if (invalidId) {
            return
          }
          commit('setChatMessages', data.data.chat_message)
        },
        error(error) {
          console.error('subscription error', error)
          commit('setIsChatSubscriptionFunctional',false)
        }
      })
      commit('setChatSubscription', subscription)
  },

  async queryChatMessages ({ dispatch, state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query chat_message($reportId: uuid) {
          chat_message(where: {report_id: {_eq: $reportId}}) {
            ${chatMessageFields(state.userData)}
          }
        }
      `,
      variables: {
        reportId: state.currentReportId
      }
    })
    if (!state.currentReport) {
      return
    }
    const invalidId = response.data.chat_message.some(chatMessage => chatMessage.report_id !== state.currentReportId)

    if (invalidId) {
      return
    }
    commit('setChatMessages', response.data.chat_message)

  }
}
