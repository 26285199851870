
export default {
  showDefaultSnackbar (state, text) {
    const lastSnackBar = state.defaultSnackbars.pop()
    const newSnackBar = { id: lastSnackBar.id++, text, show: true }
    const now = new Date()
    const filteredSnackBars = state.defaultSnackbars.filter(bar => bar.show || (now - bar.hidenOn) < 500)
    filteredSnackBars.push(newSnackBar, lastSnackBar)

    let cnt = 0

    filteredSnackBars.forEach(bar => {
      if (bar.show || bar.isPlaceHolder) {
        bar.pos = cnt++
      }
    })
    state.defaultSnackbars = filteredSnackBars
  },

  setShowDefaultLoading (state, value) {
    state.showDefaultLoading = value
  },

  hideDefaultSnackbar (state, index) {
    if (typeof index === 'number') {
      const currentSnackBar = state.defaultSnackbars[index]

      currentSnackBar.show = false
      currentSnackBar.hidenOn = new Date()

      let cnt = 0

      state.defaultSnackbars.forEach(bar => {
        if (bar.show || bar.isPlaceHolder) {
          bar.pos = cnt++
        }
      })
      const now = new Date()
      state.defaultSnackbars = state.defaultSnackbars.filter(bar => bar.show || bar.isPlaceHolder || (now - bar.hidenOn) < 500)
    } else {
      throw new Error('Mutation hideDefaultSnackbar must have index number as an argument.')
    }
  },

  setHorizontalScroll (state, value) {
    state.appRequest.horizontalScrolls = value
  },

  setShowAppBar (state, value) {
    state.appRequest.showAppBar = value
  },

  setShowAppBarLanguageSelectionDropdown (state, value) {
    state.appRequest.showAppBarLanguageSelection = value
  },

  setAppBarTitle (state, value) {
    state.appRequest.appBarTitle = value
  },

  setMiniVariant (state, miniVariant) {
    state.miniVariant = miniVariant
  }
}
