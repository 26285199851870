import apollo from '@/apollo'
import gql from 'graphql-tag'
import dayjs from "@/plugins/day"
import i18next from 'i18next'
export default {

  async loadLanguageKeys({state, commit}) {
    const response = await apollo(state).query({
      query: gql`
        query language_text {
          language_text(distinct_on: key) {
            key
          }
        }
      `
    })

    commit('setLanguageKeys', response.data.language_text)
  },

  // used by language selection
  async loadLanguages({state, commit}) {
    const response = await apollo(state).query({
      query: gql`
        query language {
          language(order_by: {order_no: asc}) {
            locale
          }
        }
      `
    })
    response.data.language.sort((a, b) => {
      if (a.locale === 'de' || b.locale === 'de') return a.locale === 'de' ? -1 : 1;
      if (a.locale === 'en' || b.locale === 'en') return a.locale === 'en' ? -1 : 1;
      return a.locale > b.locale ? 1 : -1;
    });
    await commit('setLanguages', response.data.language)

    return response.data.language
  },

  // used by translation table
  async loadAllLanguageItems({state, commit, getters}) {
    const response = await apollo(state).query({
      query: gql`
        query language {
          language(order_by: {order_no: asc}) {
            locale
            ${getters.isLteAdmin ? `
              active
              updated
              last_updated_by
              locked_by
              ` : ''
      }
            language_texts {
              id
              key
              translation
              updated
              ${(getters.isLteAdmin || getters.isTranslator) ? `
              author
              created
              language_locale
              ` : ''}
            }
          }
        }
      `
    })

    await commit('setLanguagesWithTranslationTexts', response.data.language)
    return response.data.language
  },

  // used by translation table
  subscribeToLanguages({state, getters, commit}) {
    if (state.languagesSubscription) {
      state.languagesSubscription.unsubscribe()
    }

    const observer = apollo(state).subscribe({
      query: gql`
        subscription language ($now: timestamp) {
          language (where: { language_texts: { updated: { _gt: $now } } }, order_by: {order_no: asc}) {
            locale
            ${getters.isLteAdmin ? `
              active
              updated
              last_updated_by
              locked_by
              ` : ''
      }
            language_texts (where: { updated: { _gt: $now } }) {
              id
              key
              translation
              updated
              ${(getters.isLteAdmin || getters.isTranslator) ? `
              author
              created
              language_locale
              ` : ''}
            }
          }
        }
      `,
      variables: {
        now: dayjs().toISOString()
      }
    })

    const subscription = observer.subscribe({
      next(data) {
        commit('updateLanguageTexts', data.data.language)
      },
      error(error) {
        console.error('subscription error', error)
      }
    })

    commit('setLanguagesSubscription', subscription)
  },

  async lockLanguage({dispatch}, locale) {
    await dispatch('POST', {
      url: `/rest/language-text/${locale}/lock`
    })
  },

  async unlockLanguage({dispatch}, locale) {
    await dispatch('POST', {
      url: `/rest/language-text/${locale}/unlock`
    })
  },

  async setLanguageActive({dispatch}, {locale, active}) {
    await dispatch('POST', {
      url: `/rest/language-text/${locale}/set-active?active=${active}`
    })
  },

  async loadLanguage ({ dispatch, state, commit }, { locales, reload = false }) {
    if (!Array.isArray(locales)) {
      locales = [locales]
    }

    locales = locales
      .map(locale => locale === 'nb' ? 'no' : locale)
      .filter(locale => !state.loadedLanguagesKeys.includes(locale))

    if (locales.length === 0) {
      return
    }

    try {
      const languageRequest = await dispatch('GET', {
        url: `/rest/language-text/i18n/locales?locales=${locales.join(',')}`
      })
      const languages = await languageRequest.json()
      for (const locale of Object.keys(languages)) {
        i18next.addResourceBundle(locale, 'translation', languages[locale])
        commit('addLoadedLanguageKey', locale)
        if (reload){
          await i18next.changeLanguage(locale);// forcing the reload of the language.
        }
      }
    } catch {
    }
  },

  /**
   * Loads the provided language text keys in all the provided locales, if the keys have not yet been loaded
   * @param locales locale[]
   * @param keys string[]
   * @return void
   */
  async loadLanguageTextSlice({ dispatch, state, commit}, { locales, keys }) {
    const nonLoadedLocales = locales.filter(locale => !state.loadedLanguagesKeys.includes(locale));

    const response = await apollo(state).query({
      query: gql`
        query language_text($locales: [String!]!, $keys: [String!]!) {
            language_text(where: {
              _and: {
                key: { _in: $keys },
                language_locale: { _in: $locales }
              }
            }) {
              key
              translation
              language_locale
            }
        }
        `,
        variables: {
          locales: nonLoadedLocales,
          keys
        }
    });
    return response.data.language_text;
  },

  async initializeI18nLanguage ({ dispatch, state, commit }, locale) {
    let languageRequest

    if (locale === 'nb') {
      locale = 'no'
    }

    try {
      languageRequest = await dispatch('GET', {
        url: `/rest/language-text/i18n/${locale}`,
        preventErrorShown: true
      })
    } catch {
      languageRequest = await dispatch('GET', {
        url: `/rest/language-text/i18n/en`
      })
    }
    const loadLanguagesPromise = dispatch('loadLanguages')

    const responses = await Promise.all([loadLanguagesPromise, languageRequest])
    const languageJson = await responses[1].json()
    const availableLanguages = responses[0]
    const languages = []
    for (const language of availableLanguages) {
      languages[language.locale] = {
        translation: {}
      }
    }
    languages[locale] = { translation: languageJson }
      i18next.init({
      lng: locale || state.userData?.language || 'en',
      fallbackLng: ['en', 'de'],
      defaultNS: 'translation',
      resources: languages
    })
    commit('addLoadedLanguageKey', locale)
  },

  async initializeI18nLanguages ({ dispatch, state, commit },  { loadLanguageItems } = { loadLanguageItems: true  }) {
    if (loadLanguageItems) {
      await dispatch('loadAllLanguageItems') // TODO remove this when shady loading effects in TranslationTable.vue are investigated
    }
    const i18Promise = dispatch('GET', {
      url: '/rest/language-text/i18n'
    })
    const loadLanguagesPromise = dispatch('loadLanguages')
    const responses = await Promise.all([i18Promise, loadLanguagesPromise])
    const languages = await responses[0].json()
    for (const key of Object.keys(languages)) {
      languages[key] = {
        translation: languages[key]
      }
    }

    i18next.init({
      lng: state.userData?.language || 'en',
      fallbackLng: ['en', 'de'],
      defaultNS: 'translation',
      resources: languages
    })
  }

}
