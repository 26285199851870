export default {

  moveToNextQuestion ({ state, commit, getters }) {
    const currentQuestion = state.currentQuestion
    if (!state.visitedQuestions.some(question => question.id === currentQuestion.id)) {
      commit('addVisitedQuestion', currentQuestion)
    }

    // check jumps
    let nextQuestionId
    if (currentQuestion.jumps.length) {
      for (const jump of currentQuestion.jumps) {
        const value = state.reportStatement[currentQuestion.key]
        let mustJump = false

        if (jump.type === 'IS') {
          mustJump = currentQuestion.type === 'MULTI_SELECT' ? value.includes(jump.value) : value === jump.value
        } else {
          mustJump = currentQuestion.type === 'MULTI_SELECT' ? !value.includes(jump.value) : value !== jump.value
        }

        if (mustJump) {
          nextQuestionId = jump.targetQuestionId
          break
        }
      }
    }

    if (nextQuestionId) {
      const question = getters.questions.find(question => question.id === nextQuestionId)
      commit('setCurrentQuestion', question)
      return
    }

    // check default jump
    if (currentQuestion.defaultJump) {
      const question = getters.questions.find(question => question.id === currentQuestion.defaultJump)
      commit('setCurrentQuestion', question)
      return
    }

    // next in regular process
    const index = getters.questions.findIndex(question => question.id === currentQuestion.id)
    const nextQuestion = getters.questions[index + 1]
    if (nextQuestion) {
      commit('setCurrentQuestion', nextQuestion)
    } else {
      commit('setQuestionIndex', 'SUMMARY')
    }
  },

  moveToPreviousQuestion ({ state, commit }) {
    const lastQuestion = state.visitedQuestions[state.visitedQuestions.length - 1]
    if (lastQuestion) {
      commit('popLastVisitedQuestion')
      commit('setCurrentQuestion', lastQuestion)
    } else {
      commit('setQuestionIndex', 'CATEGORY')
    }
  },

  async loadCaptchaProperties ({ dispatch, commit }) {
    const response = await dispatch('GET', {
      url: '/rest/report/captcha-properties'
    })
    const captcha = await response.json()
    captcha.siteKey = atob(captcha.siteKey)
    commit('setCaptcha', captcha)
  }

}
