import dayjs from 'dayjs'

import dayOfYear from 'dayjs/plugin/dayOfYear';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import 'dayjs/locale/de'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'
import 'dayjs/locale/it'
import 'dayjs/locale/pl'
import 'dayjs/locale/nl'
import 'dayjs/locale/pt'
import 'dayjs/locale/el'
import 'dayjs/locale/sk'
import 'dayjs/locale/cs'
import 'dayjs/locale/hu'
import 'dayjs/locale/sl'
import 'dayjs/locale/sv'
import 'dayjs/locale/ro'
import 'dayjs/locale/tr'
import 'dayjs/locale/zh'
import 'dayjs/locale/ar'
import 'dayjs/locale/fa'
import 'dayjs/locale/fi'
import 'dayjs/locale/ja'
import 'dayjs/locale/ru'
import 'dayjs/locale/ko'
import 'dayjs/locale/id'
import 'dayjs/locale/hr'


dayjs.extend(dayOfYear)
dayjs.extend(utc)
dayjs.extend(isSameOrBefore)
dayjs.extend(quarterOfYear)

const norwegianLocale = {
  name: 'no', // Locale name
  months: ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'Sugust', 'September', 'Oktober', 'November', 'Desember'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'],
  weekdays: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
  weekdaysShort: ['søn', 'man', 'tir', 'ons', 'tor', 'fre', 'lør'],
  weekdaysMin: ['sø', 'ma', 'ti', 'on', 'to', 'fr', 'lø'],
  formats: {
    LT: 'HH:mm', LTS: 'HH:mm:ss', L: 'DD.MM.YYYY', LL: 'D. MMMM YYYY', LLL: 'D. MMMM YYYY [kl.] HH:mm',
  }
};

dayjs.locale(norwegianLocale, null, true);


export default dayjs;
