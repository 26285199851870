export default {

  async loadAllPricingPlanTemplates ({ state, commit, dispatch }) {
    const response = await dispatch('GET', {
      url: '/rest/billwerk/packages'
    })

    commit('setPricingPlanTemplates', await response.json())
  }

}
