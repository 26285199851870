import router from '../../router'
import store from '../../store'
import debounce from 'debounce'

const openLoginWindow = debounce(() => {
  store.commit('setSessionExpired', true)
}, 300)

export function handleGraphQlNetworkErrors (state) {
  state.errorMessage = 'Some network error occurred. The server may be at fault.'
  state.showDefaultError = true
}

export function handleGraphQlErrors (state, graphQLErrors) {
  _handleGraphQlErrors(state, graphQLErrors)
}

function _handleGraphQlErrors (state, graphQLErrors) {
  const graphQlError = graphQLErrors[0]
  if (graphQlError?.extensions?.httpStatusEquivalent) {
    if (state?.error) {
      state.error.httpStatusEquivalent = graphQlError.extensions.httpStatusEquivalent
    }
  }

  const opener = window.opener
  if ((graphQlError?.extensions?.httpStatusEquivalent === 401 || graphQlError?.extensions?.code === 'validation-failed') && !router.currentRoute.value.query.sessionExpired && (!opener || [
    'https://app-test.whistle-report.com',
    'https://demo.whistle-report.com',
    'https://app.whistle-report.com',
    'https://lte-dev.azurewebsites.net',
    'http://localhost:8081',
    'http://localhost:8082'].includes(opener.origin))) {
    openLoginWindow() // window.open('/login?sessionExpired=1')
    state.error.isGraphQlError = true
  } else if (state) {
    state.showDefaultError = true
  }
}

export default {
  showDefaultError (state) {
    state.showDefaultError = true
  },

  setErrorMessage (state, message) {
    state.errorMessage = message
  },

  resetErrorMessage (state) {
    state.errorMessage = ''
  },

  hideDefaultError (state) {
    state.showDefaultError = false
  },

  setIsGraphQlError (state, isGraphQlError) {
    state.error.isGraphQlError = isGraphQlError
  }
}
