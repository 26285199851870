export default {
  async importLanguageText ({ dispatch, state }, data) {
    const body = new FormData()
    body.append('file', data.file)
    body.append('updateExisting', data.updateExisting)
    body.append('importAllNewLanguages', data.importAllNewLanguages)
    await dispatch('POST', {
      url: '/rest/language-text/import',
      body: body,
      isFile: true
    })
  },
  async exportLanguageText ({ dispatch, state }) {
    return await dispatch('GET', {
      url: '/rest/language-text/export'
    })
  },

  /* param = { languageText: {
  *  key: 'general.logoutTooltip',
  *  translation: 'Test 123',
  *  language: {
  *     locale: 'de'
  *   }
  * }} */
  async updateText ({ dispatch, commit }, { languageText }) {
    if (languageText.translation === '') {
      commit('updateLanguageTexts', [{ locale: languageText.language.locale, language_texts: [{ key: languageText.key, translation: '' }] }])
    }

    return await dispatch('POST', {
      url: '/rest/language-text/update-language-text',
      body: JSON.stringify(languageText)
    })
  },

  async autoTranslateTexts ({ dispatch }, { languageTexts }) {
    await dispatch('POST', {
      url: '/rest/language-text/auto-translate',
      body: JSON.stringify(languageTexts)
    })
  }
}
