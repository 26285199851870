export default {

  updateMandatoryViolations ({ state, commit }, { fieldKey, onlyRemove = false, value, validateCustomValue = false, isMandatory }) {
    if (isMandatory) {
      let isPresent = false
      const valueToCheck = validateCustomValue ? value : state.reportStatement[fieldKey]
      if (Array.isArray(valueToCheck)) {
        isPresent = valueToCheck.length > 0
      } else {
        isPresent = (!!valueToCheck || valueToCheck === 0)
      }

      if (isPresent) {
        commit('setMandatoryViolations', state.validation.mandatoryViolations.filter(mandatoryViolation => mandatoryViolation !== fieldKey))
        return true
      } else if (!onlyRemove && !state.validation.mandatoryViolations.find(mandatoryViolation => mandatoryViolation === fieldKey)) {
        commit('setMandatoryViolations', [...state.validation.mandatoryViolations, fieldKey])
      }
      return false
    }

    return true
  },

  removeMandatoryVialoation ({ state, commit }, fieldKey) {
    commit('setMandatoryViolations', state.validation.mandatoryViolations.filter(mandatoryViolation => mandatoryViolation !== fieldKey))
  },

  addInternalValidation ({ state, commit }, field) {
    if (!state.validation.internalValidations.includes(field)) {
      commit('setInternalValidations', [...state.validation.internalValidations, field])
    }
  },

  removeInternalValidation ({ state, commit }, field) {
    commit('setInternalValidations', state.validation.internalValidations.filter(_field => _field !== field))
  }

}
