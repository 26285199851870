<template>
  <v-dialog
    :class="{'allow-overflow': allowOverflow}"
    :content-class="contentClass"
    :model-value="modelValue"
    :width="width"
    @update:modelValue="value => {modelValue=true;$emit('update:modelValue', true);}"
    no-click-animation
    persistent>
    <v-card class="dialog">
      <div class="title">
        <h2 ref="title">{{ title }}</h2>
        <v-icon @click="$emit('update:modelValue', false)" v-if="!hideClose">highlight_off</v-icon>
      </div>
      <slot>
        <p class="default-text" v-dompurify-html="text"></p>
      </slot>

      <div class="actions">
        <v-btn
          class="cancel-dialog-btn"
          @click.stop="$emit('cancel')"
          color="primary"
          style="grid-column: 2;"
          variant="text"
          v-if="cancelText">{{ cancelText }}
        </v-btn>
        <div style = "display: inline-block; grid-column: 3;">
          <slot name="anotherButton"/>
          <slot name="submitButton">
            <AwaitButton
              class="submit-dialog-btn"
              :color="submitButtonColor"
              :custom-loading="loading"
              :disabled="disableSubmit"
              @click="$emit('submit')"
              emit-click
              v-if="submitText">{{ submitText }}
            </AwaitButton>
          </slot>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AwaitButton from './AwaitButton'

export default {
  name: 'Dialog',
  emits:['update:modelValue', 'cancel', 'submit'],
  components: { AwaitButton },
  props: {
    modelValue: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    cancelText: {
      type: String
    },
    submitText: {
      type: String
    },
    submitButtonColor: {
      type: String,
      default: 'primary'
    },
    text: {
      type: String
    },
    description: {
      type: String
    },
    hideClose: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '600px'
    },
    contentClass: {
      type: String,
      default: ''
    },
    disableSubmit: {
      type: Boolean,
      default: false
    },
    allowOverflow: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>

.dialog {
  display: grid;
  grid-row-gap: 20px;
  padding: 20px 30px 15px 30px;

  @media (max-width: 500px) {
    padding: 10px 15px;
  }
}

.title {
  display: grid;
  grid-template-columns: 1fr 32px;
  margin-right: -8px;
}

.default-text {
  min-height: 25px;
}

.actions {
  text-align: right;

  button {
    display: inline-block;
    margin-bottom: 5px;

    &:last-child {
      margin-left: 10px;
    }
  }
}

.v-divider {
  margin-top: 15px;
  margin-bottom: 15px;
}
.allow-overflow {
  .v-overlay__content > .v-card, .v-overlay__content > .v-sheet {
    overflow: visible !important;
   }
 }
</style>
