<template>
  <div class="not-found">
    <h1>{{ $t('notFound.title') }}</h1>
    <v-btn
      v-if="show404Page"
      @click="close()"
      style="justify-self: center;"
      color="primary">{{ $t('notFound.link') }}</v-btn>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'NotFound',
  created () {
    if (this.$route.name === 'Default404') {
      this.$router.push({ name: '404' })
    }
  },
  computed: {
    ...mapState({
      show404Page: state => state.show404Page
    })
  },
  methods: {
    ...mapMutations(['setShow404Page']),

    async close () {
      await this.$router.replace({ name: 'GeneralLogin' })
      this.setShow404Page(false)
    }
  }
}
</script>

<style scoped>
  .not-found {
    display: grid;
    align-content: center;
    justify-content: center;
    height: 100%;
  }
</style>
