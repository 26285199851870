<script>
import { mapState } from 'vuex'

export default {
  name: 'InputFieldMixin',
  props: {
    // Value of the field
    modelValue: {
      required: false,
      type: [Number,Array, String, Object] ,
      default: ''
    },
    // Whether the component is displayed on the summary
    // Will show the value in a readonly mode instead of input component
    summary: {
      type: Boolean,
      default: false
    },
    // Whether the field should show update view on the summary page
    updateOnSummary: {
      type: Boolean,
      default: false
    },
    // Option list for select field (multi select and single select)
    options: {
      type: Array,
      default: () => ([])
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    invalid: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    language: {
      type: String
    },
    showTranslation: {
      type: Boolean,
      default: false
    },
    noValue: {
      type: Boolean,
      default: false
    },
    labelledbyOverride: {
      type: String,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    handleInput (value) {
      this.$emit('update:modelValue', value)
    },

    getOptionText (option) {
      return option.label_translations[this.currentReportLanguage]
    }
  },
  computed: {
    ...mapState({
      userLanguage: state => state.language,
      currentReport: state => state.currentReport
    }),

    currentReportLanguage () {
      if (this.currentReport && this.currentReport.language) {
        return this.currentReport.language
      }

      return this.language || this.userLanguage
    },

    optionsWithTranslations () {
      return this.options.filter(option => !!this.getOptionText(option))
    }
  }
}
</script>
