<template>
  <div>
  <v-select
    id="language-select"
    v-if='!miniVariant'
    :items="sortedLanguageOptions"
    aria-label="Choose language"
    aria-describedby=""
    item-title="label"
    item-value="slug"
    :model-value="modelValue || language"
    :class="{invalid: missing}"
    class="language-selection-input"
    background-color="inherit"
    prepend-icon="language"
    density="compact"
    variant="outlined"
    hide-details
    style="max-width: 200px"
    @update:modelValue="ev =>{modelValue=ev ;$emit('update:modelValue', ev);}"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" style="width: 220px">
        {{ item.label }}
      </v-list-item>
    </template>
  </v-select>
  <v-menu max-height='400px' v-else offset-x top>
    <template v-slot:activator="{ props }">
      <v-list-item-action v-bind="props" style='margin:auto'>
        <v-btn icon>
          <div v-if='miniLanguageDisplayText'>{{ miniLanguageDisplayText }}</div>
          <v-icon v-else>translate</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
    <v-list density="compact" v-model="miniLanguageSelectorModel">
      <v-list-item v-for="langItem in sortedLanguageOptions" :key="langItem.slug" ::model-value="langItem.slug">
        <v-list-item-title>
          {{ langItem.label }}
        </v-list-item-title>
      </v-list-item>

    </v-list>
  </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'LanguageSelection',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      required: false
    },
    missing: {
      type: Boolean,
      default: false
    },
    organization: {
      type: Object,
      required: false
    },
    showAll: {
      type: Boolean,
      default: false
    },
    forceLanguageOptions: {
      type: Array,
      required: false,
      default: () => null
    },
    miniVariant: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    const locale = this.modelValue || this.language;
    if (this.sortedLanguageOptions.length !== 0 && !this.sortedLanguageOptions.find(language => language.slug === locale)) {
      this.$emit('update:modelValue', this.sortedLanguageOptions[0].slug)
    }
  },
  computed: {
    ...mapState({
      language: state => state.language,
      languages: state => state.languages,
      organizations: state => state.organizations,
      userData: state => state.userData
    }),
    ...mapGetters(['isWhb', 'currentUserOrganization', 'isCallOperator', 'languageOptionsOfOrganization', 'organizationLanguagesWithAvailableQuestionnaires', 'ownOrganization', 'formatLanguageName', 'languagesSorted']),

    languageOptions () {
      if (this.forceLanguageOptions !== null) {
        return this.forceLanguageOptions
      }

      if ((this.isWhb && !this.organizations.length) || this.showAll) {
        return this.languages.map(lang => ({
          slug: lang.locale,
          label: this.formatLanguageName(lang.locale) + ' (' + lang.locale + ')'
        }))
      } else {
        const organization = (this.isWhb || this.isCallOperator) ? this.ownOrganization : (this.organization || this.currentUserOrganization)
        if (!organization) {
          return this.languages.map(lang => {
            return { slug: lang.locale, label: this.formatLanguageName(lang.locale) }
          })
        }

        let languages = []
        if (['CustomDataProtection', 'CustomLegalNotice', 'CustomAccessibilityDeclaration'].includes(this.$route.name)) {
          const startPageFileLocales = organization.start_page_files.map(fileInformation => fileInformation.language_locale)
          const availableLanguages = organization.organization_languages.filter(organizationLanguage => startPageFileLocales.includes(organizationLanguage.language_locale))
          languages = availableLanguages.length ? availableLanguages : organization.organization_languages
        } else if (this.isWhb || this.isCallOperator) {
          const availableLanguages = this.organizationLanguagesWithAvailableQuestionnaires(organization)
          languages = availableLanguages.length ? availableLanguages : organization.organization_languages
        } else {
          languages = organization.organization_languages
        }
        return languages.map(lang => {
          return { slug: lang.language_locale, label: this.formatLanguageName(lang.language_locale) }
        })
      }
    },
    sortedLanguageOptions () {
      return this.languagesSorted(this.languageOptions)
    },

    miniLanguageSelectorModel: {
      get () {
        const valInQuestion = this.modelValue || this.language
        return this.sortedLanguageOptions.map(ite => ite.slug).indexOf(valInQuestion)
      },
      set (val) {
        const langInQuestion = this.sortedLanguageOptions[val]
        this.$emit('update:modelValue', langInQuestion.slug)
      }
    },
    miniLanguageDisplayText () {
      const valInQuestion = this.modelValue || this.language
      if (typeof valInQuestion === 'string') {
        return valInQuestion.toLocaleUpperCase()
      } else {
        return null
      }
    }
  },
}
</script>

<style scoped>
:deep(.v-input__control) {
  background-color: white;
  max-width: 200px;
}

.v-select {
  background-color: transparent !important;
}
.default-position{
  position: absolute;
  top: 10px;

}
</style>
