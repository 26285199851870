import apollo from '@/apollo'
import gql from 'graphql-tag'
import { newOrganization } from '../../../tests/unit/setup/sampleData'

export default {

  async loadMetrics ({ dispatch }) {
    dispatch('loadReportsByStatusAndCaseManager')
    dispatch('loadReportsByCategory')
    dispatch('loadPendingCases')
    dispatch('loadReportTimingAverage')
  },

  async loadReportsByStatusAndCaseManager ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query reports_by_status_and_manager($accounts: [uuid!], $deleted: String) {
          reports_by_status_and_manager (where: { status: { _neq: $deleted }${state.accountsFilter.length ? ', organization_id: { _in: $accounts }' : ''}})  {
            reports
            timeframe
            status
            organization_id
            case_manager_id
            case_manager {
              first_name
              last_name
            }
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter || [],
        deleted: 'DELETED'
      }
    })

    commit('mergeDashboardMetrics', response.data)
    return response.data
  },

  async loadReportsByCategory ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query reports_by_category($accounts: [uuid!]) {
          reports_by_category (where: {${state.accountsFilter.length ? ' organization_id: { _in: $accounts }' : ''}})  {
            label_translations
            month
            reports
            organization_id
            category_id
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter || []
      }
    })

    commit('mergeDashboardMetrics', response.data)
    return response.data
  },

  async loadPendingCases ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query case_distribution_history_entry($accounts: [uuid!]) {
          case_distribution_history_entry (
            order_by: {date: asc},
            where: {${state.accountsFilter.length ? ' organization_id: { _in: $accounts }' : ''}}
          ){
            cases_pending
            date
            organization_id
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter || []
      }
    })

    commit('mergeDashboardMetrics', response.data)
  },

  async loadReportTimingAverage ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query report_timing_avg($accounts: [uuid!]) {
          report_timing_avg (where: {${state.accountsFilter.length ? ' organization_id: { _in: $accounts }' : ''}}){
            avg_response_seconds
            avg_closing_seconds
            organization_id
            whistleblower_interaction_rate
            timeframe
          }
        }
      `,
      variables: {
        accounts: state.accountsFilter || []
      }
    })

    commit('mergeDashboardMetrics', response.data)
  },

  async createDashboardData ({ state, dispatch }, organizationId) {
    const body = new FormData()
    body.append('organizationId', organizationId)
    await dispatch('POST', {
      url: '/rest/report/create-dashboard-data',
      body: body,
      isFile: true
    })
    // TODO create organizations in the backend service
    if (state.organizations.length < 17) {
      // eslint-disable-next-line no-unused-vars
      for (let i = 0; i < 17; i++) {
        dispatch('createOrganization', newOrganization({ parent_id: organizationId }))
      }
    }
  },

  async export ({ state, dispatch }, {
    pendingReportsTimeSpan,
    reportsByCategoryTimeSpan,
    reportsByStatusTimeSpan,
    field,
    category

  }) {
    const organizationIds = Array.isArray(state.accountsFilter) ? state.accountsFilter : [state.accountsFilter]
    return await dispatch('POST', {
      url: '/rest/dashboard/export',
      body:  JSON.stringify({
        organizationIds,
        pendingReportsTimeSpan,
        reportsByCategoryTimeSpan,
        reportsByStatusTimeSpan,
        field,
        category
      })
    })
  }
}
