import { createStore } from 'vuex'
import loginMutations from './mutations/loginMutations'
import restMutations from './mutations/restMutations'
import restActions from './actions/restActions'
import reportActions from './actions/reportActions'
import reportMutations from './mutations/reportMutations'
import displayGetters from './getters/displayGetters'
import chatActions from './actions/chatActions'
import internalNotesActions from './actions/internalNotesActions'
import authorizationGetters from './getters/authorizationGetters'
import authMutations from './mutations/authMutations'
import authActions from './actions/authActions'
import subscriptionMutations from './mutations/subscriptionMutations'
import reportGetters from './getters/reportGetters'
import validationMutations from './mutations/validationMutations'
import validationActions from './actions/validationActions'
import userMutations from './mutations/userMutations'
import userActions from './actions/userActions'
import userGetters from './getters/userGetters'
import utilActions from './actions/utilActions'
import utilGetters from './getters/utilGetters'
import organizationActions from './actions/organizationActions'
import organizationMutations from './mutations/organizationMutations'
import organizationGetters from './getters/organizationGetters'
import notificationsActions from './actions/notificationsActions'
import signupMutations from './mutations/signupMutations'
import pricingPlanMutations from './mutations/pricingPlanMutations'
import pricingPlanActions from './actions/pricingPlanActions'
import notificationsMutations from './mutations/notificationsMutations'
import dashboardActions from '@/store/actions/dashboardActions'
import dashboardMutations from '@/store/mutations/dashboardMutations'
import billwerkActions from './actions/billwerkActions'
import pricingPlanGetters from './getters/pricingPlanGetters'
import billwerkGetters from './getters/billwerkGetters'
import navigationGetters from './getters/navigationGetters'
import invoiceMutations from './mutations/invoiceMutations'
import invoiceActions from './actions/invoiceActions'
import invoiceGetters from './getters/invoiceGetters'
import questionnaireTemplateActions from './actions/questionnaireTemplateActions'
import questionnaireTemplateMutations from './mutations/questionnaireTemplateMutations'
import languageTextActions from '@/store/actions/languageTextActions'
import generalMutations from './mutations/generalMutations'
import questionnaireGetters from './getters/questionnaireGetters'
import languageMutations from '@/store/mutations/languageMutations'
import languageActions from '@/store/actions/languageActions'
import newReportMutations from './mutations/newReportMutations'
import newReportActions from './actions/newReportActions'
import billwerkMutations from './mutations/billwerkMutations'
import videoActions from '@/store/actions/videoActions'
import videoGetters from '@/store/getters/videoGetters'
import languageGetters from './getters/languageGetters'
import {SIGN_IN_METHOD} from "../constants";

export const storeConfig = () => {
  return {
    state: {
      showTranslateDialog: false,
      appRequest: {
        appBarTitle: '',
        showAppBar: false,
        showAppBarLanguageSelection: false,
        horizontalScrolls: false
      },
      language: 'en',
      languages: [], // used everywhere in the app
      loadedLanguagesKeys: [], // containing the languages loaded by lazy Loading
      languagesWithTranslationTexts: [], // used by translation table
      forcedLanguages: null, // used to change the languages to the supported translations of the question while crating a report
      defaultSnackbars: [
        {
          id: 0,
          text: 'def',
          show: false,
          pos: 0,
          isPlaceHolder: true
        }
      ],
      optionLists: {
        organizations: []
      },
      organizations: [],
      filteredOrganizations: {
        data: [],
        TotalCount: 0
      },
      lawfirms: [],
      categories: [],
      followUpActions: [],
      currentOrganizationId: null,
      reports: [],
      roles: [],
      dashboardMetrics: {
        reports_by_status_and_manager: []
      },
      questionIndex: 'CATEGORY',
      visitedQuestions: [],
      currentQuestion: {},
      authToken: null,
      userData: {
        company: {
          name: 'P&T Software'
        }
      },
      organizationConfiguration: {
        manageAccounts: false,
        manageDashboard: false,
        manageReports: false,
        manageNotifications: false,
        manageUsers: false,
        userRoles: [],
        manageOrganizations: false,
        newOrganizationTypes: [],
        managePlan: false,
        manageOwnPlan: false,
        manageQuestionnaires: false
      },
      captcha: {
        disable: true,
        siteKey: ''
      },
      currentReport: null,
      currentReportStatements: {},
      reportStatement: {},
      selectedReports: [],
      currentReportId: null,
      currentReportNumber: null,
      questionnaireTemplates: [],
      questionnaireTemplateVersions: [],
      questionnaire: {
        questions: [
          {
            id: 1,
            type: 'PERSONAL_INFORMATION',
            key: 'personalInformation',
            // disabled: true,
            title_translations: {
              de: 'Persönliche Informationen',
              en: 'Personal information'
            },
            description_translations: {
              de: '',
              en: ''
            },
            mandatory: true,
            options: [],
            jumps: [],
            defaultJump: null,
            settings: {
              show_country: true,
              show_job_title: true,
              show_phone: true,
              mandatory_phone: false,
              mandatory_email: false
            }
          },
          {
            id: 2,
            type: 'LONG_ANSWER',
            key: 'information',
            title_translations: {
              de: 'Bitte beschreiben Sie die Informationen, die Sie teilen möchten, so detailliert wie möglich',
              en: 'Please describe the information you wish to share in as much detail as possible'
            },
            description_translations: {
              de: '',
              en: ''
            },
            mandatory: true,
            options: [],
            jumps: [],
            defaultJump: null
          },
          {
            id: 3,
            type: 'SHORT_ANSWER',
            key: 'location',
            title_translations: {
              de: 'Wo ist der Vorfall passiert?',
              en: 'Where did the incident occur?'
            },
            description_translations: {
              de: '',
              en: ''
            },
            mandatory: true,
            options: [],
            jumps: [],
            defaultJump: null
          },
          {
            id: 4,
            type: 'DATE',
            key: 'date',
            title_translations: {
              de: 'Wann ist der Vorfall passiert?',
              en: 'When did the incident occur?'
            },
            description_translations: {
              de: 'Wenn Sie sich nicht an den genauen Tag erinnern oder sich der Vorfall mehrfach wiederholt hat, können Sie dies später ergänzen. Geben Sie hier das Datum des Vorfalls an, an das Sie sich ungefähr erinnern. Sie können dieses Feld auch leer lassen.',
              en: 'If you do not remember the exact day or the incident has repeated itself several times, you can add this later. Enter the date of the incident that you roughly remember. You can also leave this field empty.'
            },
            mandatory: false,
            must_be_in_the_past: true,
            options: [],
            jumps: [],
            defaultJump: null
          },
          {
            id: 5,
            type: 'SHORT_ANSWER',
            key: 'involvedPersons',
            title_translations: {
              de: 'Wer waren die beteiligten Personen?',
              en: 'Who were the people involved?'
            },
            description_translations: {
              de: '',
              en: ''
            },
            mandatory: true,
            options: [],
            jumps: [],
            defaultJump: null
          },
          {
            id: 6,
            type: 'BOOLEAN',
            key: 'relationshipToCompany',
            title_translations: {
              de: 'Das Whistleblower-Programm wird von {{ company }} verwaltet.\nBezieht sich dieser Bericht auf Personen, die derzeit mit oder für {{ company }} arbeiten?',
              en: 'The Whistleblower program is administered by {{ company }}.\nDoes this report refer to persons currently working with or for {{ company }}?'
            },
            description_translations: {
              de: '',
              en: ''
            },
            mandatory: true,
            options: [],
            jumps: [],
            defaultJump: null
          },
          {
            id: 7,
            type: 'SINGLE_SELECT',
            key: 'personRelationshipToCompany',
            title_translations: {
              de: 'In welcher Beziehung stehen Sie zu {{ company }}?',
              en: 'What is your relationship with {{ company }}?'
            },
            description_translations: {
              de: '',
              en: ''
            },
            mandatory: true,
            options: [
              {
                id: 'COWORKER',
                label_translations: {
                  de: 'Mitarbeiter',
                  en: 'Coworker'
                }
              },
              {
                id: 'MANAGER',
                label_translations: {
                  de: 'Manager',
                  en: 'Manager'
                }
              },
              {
                id: 'CUSTOMER',
                label_translations: {
                  de: 'Kunde',
                  en: 'Customer'
                }
              },
              {
                id: 'PARTNER',
                label_translations: {
                  de: 'Partner',
                  en: 'Partner'
                }
              }
            ],
            jumps: [],
            defaultJump: null
          },
          {
            id: 8,
            type: 'LONG_ANSWER',
            key: 'additionalInformation',
            title_translations: {
              de: 'Gibt es noch etwas, das Sie uns mitteilen möchten?',
              en: 'Is there anything else you would like to tell us?'
            },
            description_translations: {
              de: 'Wenn sich der Vorfall mehrfach wiederholt hat, können Sie dies hier ergänzen. Auch andere Ergänzungen können Sie hier vornehmen.',
              en: 'If the incident has repeated itself several times, you can add this here. You can also make other additions here.'
            },
            mandatory: false,
            options: [],
            jumps: [],
            defaultJump: null
          },
          {
            id: 9,
            type: 'FILE_UPLOAD',
            key: 'files',
            disabled: true,
            title_translations: {
              de: 'Laden Sie die unterstützende Dokumentation hoch',
              en: 'Upload the supporting documentation'
            },
            description_translations: {
              de: 'Gibt es etwas, dass als Beweis des Vorfalls dienen könnte? Bitte laden Sie hier entsprechende Dokumente, Bilder, Videos oder anderes hoch. Wir entfernen alle Metadaten, so dass damit niemand nachvollziehen kann, wer die Daten hochgeladen hat. Falls im Inhalt der Dokumente aber Hinweise auf Ihre Person zu finden sind, müssen Sie diese vorher beseitigen, wenn Sie die Anonymität sicherstellen wollen.',
              en: 'Is there anything that could serve as evidence of the incident? Please upload relevant documents, pictures, videos or other material here. We will remove all metadata so that no one can trace who uploaded the data. However, if there are references to your person in the content of the documents, you must remove them first if you want to ensure anonymity'
            },
            options: [],
            jumps: [],
            defaultJump: null
          }
        ]
      },
      chatSubscription: null,
      isChatSubscriptionFunctional:null,
      noteSubscription: null,
      fileSubscription: null,
      languagesSubscription: null,
      categorySubscription: null,
      setCategoryMetadataSubscription: null,
      notificationSubscription: null,
      unreadNotificationsSubscription: null,
      showDefaultError: false,
      errorMessage: '',
      showDefaultLoading: false,
      languageKeys: [],
      miniVariant: false,

      sessionExpired: false,
      emailAlreadyExistsDialog: false,
      planExpires: false,

      show404Page: false,
      pricingPlanExpires: false,
      error: {
        isGraphQlError: false,
        httpStatusEquivalent: null
      },

      validation: {
        mandatoryViolations: [],
        internalValidations: []
      },

      organizationsTableOptions: {
        search: '',
        orderBy: [{ key: 'name', order: 'asc' }],
        paginator :  {
          page: 1,
          itemsPerPage: 10,
        },
        filter: {
          managedByLawfirm: []
        }
      },

      reportsTableOptions: {
        search: '',
        filter: {
          status: [
            'PENDING',
            'IN_PROGRESS',
            'REVIEW',
            'CLOSED',
            'ARCHIVED'
          ],
          caseManager: [],
          category: [],
          followUpAction: [],
          date: []
        },
        caseManagerOptions: [],
        categoryOptions: [],
        followUpActionOptions: [],
        vuetifyOptions: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [{ key: 'case_id', order: 'desc' }, { key: 'updated', order: 'desc' }],
          groupBy: [],
          multiSort: true,
          mustSort: true
        }
      },

      invoiceTableOptions: {
        dateFilter: []
      },

      usersTableOptions: {
        search: '',
        orderBy: [{ key: 'organization.name', order: 'desc' }, { key: 'last_name', order: 'asc' }],
        paginator :  {
          page: 1,
          itemsPerPage: 10,
        },
        filter: {
          organization: []
        }
      },

      accountsFilter: [],
      users: [],
      usersFiltered: {
        data: [],
        TotalCount: 0
      },

      notifications: [],
      notificationOffset: 0,
      notificationLength: 0,
      unreadNotifications: 0,

      signupStepper: 1,

      pricingPlanTemplates: [],
      pricingPlans: [],

      signupSme: {
        active: true,
        name: '',
        dtype: 'Sme',
        employee_count: '',
        vat_id: '',
        registration_number: '',
        street_address: '',
        additionalStreetInfo: '',
        city: '',
        zip_code: '',
        country: 'DE',
        primary_contact_first_name: '',
        primary_contact_last_name: '',
        primary_contact_email: '',
        pricing_plan: {},
        alternative_billing_address: false,
        alternativeBillingAddressData: {},
        sign_in_method: SIGN_IN_METHOD.CREDENTIALS
      },

      showWelcomePopup: false,

      invoices: [],

      extendedContractId: ''
    },
    mutations: {
      ...loginMutations,
      ...newReportMutations,
      ...restMutations,
      ...reportMutations,
      ...authMutations,
      ...subscriptionMutations,
      ...validationMutations,
      ...userMutations,
      ...organizationMutations,
      ...signupMutations,
      ...pricingPlanMutations,
      ...notificationsMutations,
      ...dashboardMutations,
      ...invoiceMutations,
      ...questionnaireTemplateMutations,
      ...generalMutations,
      ...languageMutations,
      ...billwerkMutations
    },
    actions: {
      ...restActions,
      ...reportActions,
      ...chatActions,
      ...internalNotesActions,
      ...authActions,
      ...validationActions,
      ...userActions,
      ...utilActions,
      ...organizationActions,
      ...notificationsActions,
      ...pricingPlanActions,
      ...dashboardActions,
      ...billwerkActions,
      ...invoiceActions,
      ...questionnaireTemplateActions,
      ...languageTextActions,
      ...languageActions,
      ...newReportActions,
      ...videoActions
    },
    getters: {
      ...displayGetters,
      ...authorizationGetters,
      ...reportGetters,
      ...userGetters,
      ...utilGetters,
      ...organizationGetters,
      ...pricingPlanGetters,
      ...billwerkGetters,
      ...navigationGetters,
      ...invoiceGetters,
      ...questionnaireGetters,
      ...videoGetters,
      ...languageGetters
    },
    modules: {}
  }
}

const store = createStore(
  storeConfig()
)
export default store;
