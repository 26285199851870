import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from 'apollo-link-error'
import {handleGraphQlErrors, handleGraphQlNetworkErrors} from './store/mutations/restMutations'

let wsUrl = ''
let httpUrl = ''
// ex: window.location.hostname = app-test.whistle-report.com => ["app-test", "whistle-report", "com"]
let environment = window.location.hostname.split('.')[0]
for (const possibleEnv of ['dev', 'test']) { // account for special domain names
  if (environment.includes(possibleEnv)) {
    environment = `app-${possibleEnv}`
  }
}
if (environment.includes('-internal')) { // make sure '-internal' suffix is not repeated
  environment = environment.split('-internal')[0]
}

// Create an http link:
if (['localhost', 'anotherhost', 'thirdhost'].includes(window.location.hostname)) {
  wsUrl = 'ws://' + process.env.VUE_APP_LOCAL_GRAPHQL_URL
  httpUrl = 'http://' + process.env.VUE_APP_LOCAL_GRAPHQL_URL
} else {
  wsUrl = 'wss://' + `${environment}.whistle-report.com/v1/graphql`
  httpUrl = 'https://' + `${environment}.whistle-report.com/v1/graphql`
}

const httpLink = (authToken) => {
  return new HttpLink({
    uri: httpUrl,
    headers: {
      'x-auth-token': authToken
    }
  })
}

// Create a WebSocket link:
const wsLink = (authToken) => {
  return new WebSocketLink({
    uri: wsUrl,
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: {
        headers: {
          'x-auth-token': authToken
        }
      }
    }
  })
}

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = (authToken) => {
  return split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink(authToken),
    httpLink(authToken)
  )
}

const errorLink = function (state) {
  return onError(({ graphQLErrors, networkError, operation }) => {
    console.error('GraphQlError:', graphQLErrors)
    console.error('NetworkError:', networkError)
    console.log('OPERATION', operation)

    if (graphQLErrors) {
      handleGraphQlErrors(state, graphQLErrors)
    }
    if (networkError) {
      handleGraphQlNetworkErrors(state)
    }
  })
}

export default function (state, handleErrors = true) {
  return new ApolloClient({
    addTypename: false,
    link: handleErrors ? errorLink(state).concat(link(state.authToken)) : link(state.authToken),
    cache: new InMemoryCache({
      addTypename: false
    }),
    connectToDevTools: ['localhost'].includes(window.location.hostname),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        notifyOnNetworkStatusChange: true
      }
    }
  })
}
