export default {
  setNoteSubscription (state, observer) {
    state.noteSubscription = observer

  },

  setChatSubscription (state, observer) {
    state.chatSubscription = observer
  },

  setIsChatSubscriptionFunctional (state, value) {
    state.isChatSubscriptionFunctional = value
  },

  setFileSubscription (state, observer) {
    state.fileSubscription = observer
  },

  setNotificationSubscription (state, observer) {
    state.notificationSubscription = observer
  },

  setUnreadNotificationsSubscription (state, observer) {
    state.unreadNotifications = observer
  }
}
