<template>
  <v-list v-if="helpLink" density="compact" style="padding-top:0; margin-top:-1px;">
    <v-list-item v-if='!miniVariant && (!!helpLink || (!isCallOperator && (isBookable || hasAccess)))' key='knowledgeSeperator' title="">
      <v-list-item-title>{{ $t('navigation.knowledge') }}</v-list-item-title>
    </v-list-item>

    <NavigationItem
      v-if="helpLink"
      item-key="helpBtn"
      :href="helpLink"
      icon-name="help"
      title-label="navigation.helpLink"
    />
  </v-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavigationItem from './NavigationItem'

export default {
  name: 'NavigationListKnowledge',
  components: { NavigationItem },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant
    }),
    ...mapGetters([
      'isCallOperator',
      'isWhiteLabel',
      'isGlobalUser',
      'isBookable',
      'hasAccess'
    ]),
    helpLink () {
      let link = ''
      if (this.isWhiteLabel) {
        link = 'https://portal.whistle-report.com'
      } else if (!this.isGlobalUser) {
        link = 'https://portal.legaltegrity.com'
      }
      return link
    }
  }
}
</script>
