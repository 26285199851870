<template>
  <div style="position: relative" @click.stop="datepickerVisible = true">
    <VueDatePicker
      id="date-field"
      :tabindex="0"
      :max-date="mustBeInThePast ? new Date() : ''"
      v-if="!summary || updateOnSummary"
      :enable-time-picker="false"
      alt="Date picker"
      :format="'dd.MM.yyyy'"
      aria-labelledby="question-number question-label question-hint"
      :aria-invalid="invalid"
      :locale="this.language"
      v-model="valueForDatepicker"
      @update:model-value="dateInput"
      :text-input-options="textInputOptions"
      class="date-field"
      :class="{invalid}"
      text-input
      auto-apply
      :clearable="false"
    />
    <p v-else v-dompurify-html="inputValue || $t('questionnaire.general.noAnswer', { lng: currentReportLanguage })" class="accent-color"></p>

    <!--    Problem disabled-dates it accepts arrays only-->
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import InputFieldMixin from './InputFieldMixin'

export default {
  name: 'DateField',
  mixins: [InputFieldMixin],
  components: {
    VueDatePicker
  },
  props: {
    mustBeInThePast: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.summary || this.updateOnSummary) {
      if (this.autofocus && !this.$vuetify.display.mobile && !this.preview) {
        this.$nextTick(()=>{
          document.getElementById('date-field').focus()
        });
      }
    }
  },
  data: () => {
    return {
      languages: ['en'],
      inputValue: '',
      watchedLanguage: {},
      datepickerVisible: false,
      valueForDatepicker: null,
      formatOptions: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      },
      textInputOptions: {
        format: 'dd.MM.yyyy'
      }
    }
  },
  created () {
    this.setLocalValue()
    this.watchedLanguage = this.languages[this.language]
  },
  watch: {
    /* Needed for v-model implementation. Sets the value of the inputValue to the 'value' property */
    modelValue () {
      this.setLocalValue()
    },
    language (newValue) {
      this.watchedLanguage = this.languages[newValue]
    }
  },
  methods: {
    setLocalValue () {
      this.inputValue = this.modelValue

      if (this.modelValue) {
        const dateParts = this.modelValue.split('.')
        if (dateParts.length === 3) {
          this.valueForDatepicker = `${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`
        }
      }
    },

    /* Handles an input in the calendar overlay. Updates the input value and closes the overlay. */

    dateInput (value) {
      this.inputValue = null
      if (value != null && typeof value === 'object') {
        this.inputValue = value.toLocaleDateString(
          'de-DE',
          this.formatOptions
        )
      }

      this.$emit('update:modelValue', this.inputValue)

      this.valueForDatepicker = value

    }
  }

}
</script>
<style lang="scss" scoped>
:deep(.dp__today) {
  border-color: var(--accent-color);
}

:deep(.dp__action_select,.dp__action_select:hover), :deep(.dp__active_date) {
  background: var(--accent-color);
}

.invalid :deep(.dp__input) {
  padding-left: 6px;
  border-color: var(--destruction);
  border-width: 2px;
}

:deep(.dp__icon) {
  width: 24px;
  height: 24px;
}

:deep(.dp__input) {
  --dp-input-icon-padding: 40px;
}
</style>
