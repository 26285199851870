import dayjs from "dayjs";

export default {

  invoiceDateFilterSorted (state) {
    return state.invoiceTableOptions.dateFilter.slice().sort((a, b) => {
      const aMoment = dayjs(a, 'YYYY-MM-DD')
      const bMoment = dayjs(b, 'YYYY-MM-DD')
      return aMoment.isBefore(bMoment) ? -1 : 1
    })
  }

}
