<template>
  <v-tooltip right
             :disabled="!miniVariant"
             :key='itemKey'
  >
    <template v-slot:activator="{props}">
      <v-list-item
        v-bind='props'
        :to="routeName ? {name: routeName} : undefined"
        :href="href ? href : undefined"
        :target="href ? '_blank' : undefined"
        link
        title=""
      >
        <div class="list-item">
          <div class="list-item-icon">
            <v-icon
              size="x-small">
              <v-icon>{{ iconName }}</v-icon>
            </v-icon>
          </div>

          <div class="list-item-content">
            {{ $t(titleLabel) }}
          </div>


          <div class="list-item-action">
            <v-btn
              icon
              density="compact"
              variant="flat"
            >
              <v-icon>
                keyboard_arrow_right
              </v-icon>
            </v-btn>
          </div>

        </div>
      </v-list-item>
    </template>
    <label>{{ $t(titleLabel) }}</label>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavigationItem',
  props: {
    itemKey: {
      type: String,
      required: true
    },
    routeName: {
      type: String
    },
    href: {
      type: String
    },
    iconName: {
      type: String,
      required: true
    },
    titleLabel: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant
    })
  }
}
</script>

<style lang="scss" scoped>

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 20px;
}
.list-item-action {
  margin-inline-start:auto;
}
</style>
