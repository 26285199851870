<template>
  <v-list density="compact" style='padding-top:0;margin-top:-1px; padding-bottom:0;' title="">
    <v-list-item v-if="canNavigateAccounts && !miniVariant" key='accountSeparator'  title="">
      <v-list-item-title>{{ $t('navigation.account') }}</v-list-item-title>
    </v-list-item>
    <AccountSwitcher/>
    <v-list-item v-if='!miniVariant && organizationTopAccount' key='miniCustomerReference' title="">

      <v-list-item-title>{{ $t('navigation.mandateReference.label') }} :
        {{ organizationTopAccount.customer_reference }}
      </v-list-item-title>

    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AccountSwitcher from './AccountSwitcher'

export default {
  name: 'NavigationListAccount',
  components: { AccountSwitcher },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant
    }),
    ...mapGetters([
      'organizationTopAccount',
      'canNavigateAccounts'
    ])
  }
}
</script>
