export default {
  setQuestionIndex (state, index) {
    state.questionIndex = index
  },
  setReports (state, reports) {
    state.reports = reports
  },
  setReportStatementField (state, { key, value }) {
    state.reportStatement[key] = value
  },
  resetNewReportStatements (state) {
    state.reportStatement = {}
    state.visitedQuestions = []
  },
  setCurrentQuestion (state, question) {
    state.currentQuestion = question
  },
  addVisitedQuestion (state, question) {
    state.visitedQuestions.push(question)
  },
  popLastVisitedQuestion (state) {
    const copy = [...state.visitedQuestions]
    copy.pop()
    state.visitedQuestions = copy
  },
  setCaptcha (state, captcha) {
    state.captcha = captcha
  }
}
