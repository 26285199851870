export default {
  setQuestionnaireTemplates (state, questionnaireTemplates) {
    state.questionnaireTemplates = questionnaireTemplates
  },

  setQuestionnaireTemplateVersions (state, questionnaireTemplateVersions) {
    state.questionnaireTemplateVersions = questionnaireTemplateVersions
  },

  setCategorySubscription (state, subscription) {
    state.categorySubscription = subscription
  },

  setCategoryMetadataSubscription (state, subscription) {
    state.categoryMetadataSubscription = subscription
  }
}
