<template>
  <span style='position:"fixed"; display:"none"'></span>
</template>

<script>
export default {
  name: 'ScrollProvider',
  data: function () {
    return {
      parentEl: null,
      overflow: null,
      height: null,
      htmlOverflow: null
    }
  },
  mounted () {
    this.parentEl = this.$el.parentElement
    this.overflow = this.parentEl.style.overflow
    this.height = this.parentEl.style.height
    this.htmlOverflow = document.body.parentElement.style.overflow
    this.parentEl.style.overflow = 'auto'
    this.parentEl.style.height = '100vh'
    document.body.parentElement.style.overflow = 'visible'
    this.parentEl.scrollTop = '0px'
    this.parentEl.scrollLeft = '0px'
  },

  beforeUnmount () {
    this.parentEl.style.overflow = this.overflow
    this.parentEl.style.height = this.height
    document.body.parentElement.style.overflow = this.htmlOverflow
  }
}
</script>
