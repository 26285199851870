import {SIGN_IN_METHOD, PRIMARY_COLOR_CODE, START_PAGE_FILE_TYPES} from '@/constants'
import  {globalProperties} from '@/main'
export default {

  getOrganizationStatus (organization) {
    if (organization.active === false) {
      return 'disabled'
    } else if (organization.suspended === true) {
      return 'suspended'
    }
    return 'active'
  },

  whbOrganization: (state) => {
    if (!state.organizations?.length) {
      return null
    }
    if (state.organizations.length > 1) {
      return state.organizations.find(orga => orga.id === state.currentOrganizationId)
    }
    return state.organizations[0]
  },

  whbPortalLink: (state, getters) => (organizationId) => {
    const origin = globalProperties.$BASE_URL
    return `${origin}/report/${organizationId}`
  },

  loginLink: (state, getters) => (organizationId) => {
    const origin = globalProperties.$BASE_URL
    return `${origin}/${organizationId}/login`
  },

  /**
   * Beware that this might not give the correct result if organization object is not fully hydrated (e.g. parent field
   * must be set recursively). Compare Organization.isWhiteLabel() in back end for definition.
   */
  isOrganizationWhiteLabel: (state, getters) => (organization) => {
    if (!organization) {
      return false
    }
    let parent = organization
    do {
      if (parent.dtype === 'LawFirm') {
        return true
      }
      parent = parent.parent
    } while (parent)
  },

  currentUserOrganization (state, getters) {
    if (getters.isCallOperator || (!state.userData && state.organizations?.length)) {
      return getters.whbOrganization
    }

    if (!state.userData || !state.organizations?.length) {
      return null
    }

    return state.organizations.find(org => org.id === state.userData.organization_id)
  },

  canCurrentUserOrganizationHasChildren (state, getters) {
    if (!getters.currentUserOrganization) {
      return false
    }
    const organizationConfiguration = getters.currentUserOrganization.organization_configuration
    if (!organizationConfiguration?.new_organization_types?.length) {
      return false
    }

    return true
  },

  currentUserOrganizationHasLawFirmManagedPlan (state, getters) {
    if (!getters.currentUserOrganization) {
      return false
    }

    const plan = getters.currentUserOrganization?.pricing_plan
    return !!plan?.plan_variant_name?.toLowerCase().includes('law firm managed')
  },

  currentUserOrganizationHasContractId (state, getters) {
    // eslint-disable-next-line camelcase
    return !!getters.currentUserOrganization?.pricing_plan?.contract_id
  },

  currentUserOrganizationHasCustomerId (state, getters) {
    // eslint-disable-next-line camelcase
    return !!getters.currentUserOrganization?.pricing_plan?.customer_id
  },

  currentUser (state) {
    if (!state.userData || !state.users?.length) {
      return null
    }

    return state.users.find(user => user.id === state.userData.id)
  },

  hasExceededNumberOfUsers (state, getters) {
    if (getters.isGlobalUser) {
      return true
    }

    const filteredOrganizations = getters.organizationsFilter.filter(org => org.pricing_plan)
    const activeUsers = state.users.filter(user => user.active)

    const userConfigInFilteredOrganizations = filteredOrganizations.map(org => ({
      // count users who are active and who belong to an filtered organization or who has role in the filtered organization
      count: activeUsers.filter(user => (user.organization && user.organization.id === org.id) || !!(user.role_assignments.find(assignment => assignment.organization_id === org.id))).length,
      allowed: org.pricing_plan.user_accounts
    }))
    const sumOfUsers = userConfigInFilteredOrganizations.map(conf => conf.count).reduce((a, b) => a + b, 0)
    const sumOfAllowedUsers = userConfigInFilteredOrganizations.map(conf => conf.allowed).reduce((a, b) => a + b, 0)
    return sumOfUsers >= sumOfAllowedUsers
  },

  organizationHasReachedUserCapacity: (state, getters) => (organizationId) => {
    const organization = state.organizations.find(organization => organization.id === organizationId)
    if (organization.sign_in_method === SIGN_IN_METHOD.TNM_MICROSOFT) {
      // allow unlimited active user role assignments for TNM organizations
      return false;
    }
    const organizationActiveAssignedRolesCount = state.users.filter(user => user.active && !!user.role_assignments.find(roleAssignment => roleAssignment.organization_id === organizationId)).length
    return organization.pricing_plan && (organizationActiveAssignedRolesCount >= organization.pricing_plan.user_accounts)
  },

  allowedToEditBranding (state, getters) {
    if (getters.isGlobalUser) {
      return true
    }

    let ownOrganization
    if (getters.isImpersonated) {
      const organization = state.organizations.find(org => org.id === state.accountsFilter)
      return organization?.pricing_plan.branding_allowed
    }
    if (state.userData) {
      ownOrganization = state.organizations.find(org => org.id === state.userData.organization_id)
    } else {
      ownOrganization = state.organizations.find(org => org.id === state.currentOrganizationId)
    }
    return ownOrganization?.pricing_plan.branding_allowed
  },

  organizationAllowedToEditBranding: () => (organization) => {
    return organization.pricing_plan ? organization.pricing_plan.branding_allowed : true
  },

  canCustomizeQuestionnaire (state, getters) {
    if (getters.isLteAdmin) return true
    let ownOrganization

    if (getters.accountSelected) {
      const organization = getters.organizationSelected
      return organization?.pricing_plan?.custom_questionnaire_allowed
    } else if (state.userData) {
      ownOrganization = state.organizations.find(org => org.id === state.userData.organization_id)
    } else {
      ownOrganization = state.organizations.find(org => org.id === state.currentOrganizationId)
    }
    return ownOrganization?.pricing_plan?.custom_questionnaire_allowed
  },

  organizationCanCustomizeQuestionnaire: () => (organization) => {
    return organization.pricing_plan ? organization.pricing_plan.custom_questionnaire_allowed : true
  },

  organizationLanguagesWithAvailableQuestionnaires: (state, getters) => (organization) => {
    if (organization?.pricing_plan?.custom_questionnaire_allowed) {
      return organization.organization_languages.filter(language => {
        return organization.report_categories.filter(category => {
          return category.organization_id !== null &&
            category.is_active &&
            !!category.label_translations[language.language_locale] &&
            category.label_translations[language.language_locale] !== '' &&
            category.questionnaire &&
            getters.questionnaireSupportsLanguage(category.questionnaire, language.language_locale)
        }).length > 0
      })
    } else {
      return organization?.organization_languages.filter(language => {
        return state.categories.filter(category => !!category.label_translations[language.language_locale] &&
          category.questionnaire &&
          getters.questionnaireSupportsLanguage(category.questionnaire, language.language_locale)).length
      })
    }
  },

  isOrganizationSetup: (state, getters) => {
    const currentUserOrganization = getters.currentUserOrganization
    return getters.isOrganizationAdmin && currentUserOrganization && !currentUserOrganization.branding_setup_completed
  },

  isOrganizationSetupWithBranding: (state, getters) => {
    const organizationSetup = getters.isOrganizationSetup
    const currentUserOrganization = getters.currentUserOrganization
    return organizationSetup && currentUserOrganization?.pricing_plan?.branding_allowed
  },

  organizationsFilter: (state, getters) => {
    if (!state.organizations || state.organizations.length === 0) {
      return []
    }

    const accountsFilterArray = getters.accountsFilterArray

    return state.organizations
      .filter(organization => accountsFilterArray.includes(organization.id))
      .sort((a, b) => accountsFilterArray.indexOf(a.id) - accountsFilterArray.indexOf(b.id))
  },

  organizationTopAccount: (state, getters) => {
    const organizations = getters.organizationsFilter
    if (organizations.length === 0) {
      return null
    }

    return organizations[0]
  },

  organizationSelected: (state, getters) => {
    if (!getters.accountSelected || !state.organizations || state.organizations.length === 0) {
      return null
    }

    return getters.organizationsFilter[0]
  },

  ownOrganization: (state, getters) => {
    if (!state.userData && state.organizations?.length) {
      return getters.whbOrganization
    }

    if (!state.userData || !state.organizations?.length) {
      return null
    }

    if (getters.isCallOperator) {
      return state.organizations?.[0]
    }

    return state.organizations.find(org => org.id === state.userData.organization_id)
  },

  organizationPrimaryColor: (state, getters) => (organization) => {
    const accountsFilterArray = getters.accountsFilterArray
    if (!organization) organization = (accountsFilterArray?.length ===1) ? state.organizations.find(organization => organization.id === accountsFilterArray[0]) :getters.currentUserOrganization
    if (!organization) return PRIMARY_COLOR_CODE

    if (organization.color_code_primary) {
      return organization.color_code_primary
    }

    const parent = state.organizations.find(org => org.id === organization.parent_id)
    if (parent?.color_code_primary && !organization.color_code_primary) {
      return parent.color_code_primary
    }

    if (!organization.id && getters.currentUserOrganization?.color_code_primary) {
      return getters.currentUserOrganization.color_code_primary
    }

    return PRIMARY_COLOR_CODE
  },

  organizationStartPageFile: (state, getters) => (organization, fileType, locale) => {
    if (!organization) {
      return undefined
    }
    const startPageFile = organization?.start_page_files.find(file =>
      file.file_type === fileType &&
      file.language_locale === locale
    )

    if (startPageFile) {
      return startPageFile
    }

    if (organization.parent_id || organization.parent?.id) {
      const parentId = organization.parent_id ?? organization.parent.id
      const parent = state.organizations.find(org => org.id === parentId)
      return getters.organizationStartPageFile(parent, fileType, locale)
    }

    return undefined
  },

  organizationStartPageFileOrLink: (state, getters) => (organization, fileType, locale) => {
    if (!organization) {
      return undefined
    }

    const startPageLink = organization?.start_page_configuration? organization?.start_page_configuration[getters.organizationStartPageConfigurationKey(fileType)][locale]: null
    if (startPageLink) return {
      link: startPageLink,
      isLink: true
    }

    const startPageFile = organization?.start_page_files.find(file =>
      file.file_type === fileType &&
      file.language_locale === locale
    )

    if (startPageFile) return {
      file: startPageFile,
      isLink: false
    }

    if (organization.parent_id || organization.parent?.id) {
      const parentId = organization.parent_id ?? organization.parent.id
      const parent = state.organizations.find(org => org.id === parentId)
      return getters.organizationStartPageFileOrLink(parent, fileType, locale)
    }

    return undefined
  },

  organizationFaqFileOrLink: (state, getters) => (organization, fileType, locale) => {
    const faqEnabled = organization?.start_page_configuration.faq_enabled

    if (!organization || faqEnabled === false) {
      return undefined
    }
    const link = Object.entries(organization?.start_page_configuration.faq.link).find(([key, value]) => key === locale && value)
    const name = Object.entries(organization?.start_page_configuration.faq.name).find(([key, value]) => key === locale && value)
    // Test the link value because we have an array as a result in this format ['en', 'faq']
    if (link?.[1] || name?.[1]) {
      return {
        isLink: true,
        faq_enabled: faqEnabled,
        link: {
          name: name?.[1],
          link: link?.[1],
        }
      }
    }

    const startPageFile = organization?.start_page_files.find(file =>
      file.file_type === fileType &&
      file.language_locale === locale
    )

    if (startPageFile) return {
      file: startPageFile,
      isLink: false,
      faq_enabled: faqEnabled,
    }

    if (organization?.parent_id || organization?.parent?.id) {
      const parentId = organization.parent_id ?? organization.parent.id
      const parent = state.organizations.find(org => org.id === parentId)
      return getters.organizationFaqFileOrLink(parent, fileType, locale)
    }

      return undefined;
  },

  organizationStartPageConfigurationKey : (state, getters) => (fileType) => {
    switch (fileType) {
      case START_PAGE_FILE_TYPES.DATA_PROTECTION:
        return 'data_protection_link'
      case START_PAGE_FILE_TYPES.LEGAL_NOTICE:
        return 'legal_notice_link'
      case START_PAGE_FILE_TYPES.ACCESSIBILITY_DECLARATION:
        return 'accessibility_declaration_link'
      case START_PAGE_FILE_TYPES.FAQ:
        return 'faq'
    }
    return ''
  },

  organizationHasSignInMethodExternal: (state) => (organizationId) => {
    const organization = state.organizations.find(organization => organization.id === organizationId)
    return organization && organization.sign_in_method !== SIGN_IN_METHOD.CREDENTIALS
  },

  organizationHasSignInMethodTnm: (state) => (organizationId) => {
    const organization = state.organizations.find(organization => organization.id === organizationId)
    return organization && organization.sign_in_method === SIGN_IN_METHOD.TNM_MICROSOFT
  },

  currentUserOrganizationHasSignInMethodTnm: (state, getters) => {
    const organization = getters.currentUserOrganization;
    return getters.organizationHasSignInMethodTnm(organization?.id)
  },

}
