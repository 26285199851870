import { Base64 } from 'js-base64';
import {TEXT_TYPE} from "./constants";
import {findPhoneNumbersInText} from "libphonenumber-js";

export function atobWithFallback(value) {
  try {
    const x = Base64.decode(value)

    try {
      if (x?.includes('\uFFFD')) {
        return atob(value)
      }
    } catch {

    }

    return x
  } catch (e) {
    return atob(value)
  }
}

export function btoaWithFallback(value) {
  try {
    return Base64.encode(value)
  } catch {
    return btoa(value)
  }
}

export function debounceAsync(func, wait) {
  let timeout;
  return function(...args) {
    return new Promise((resolve, reject) => {
      const later = () => {
        clearTimeout(timeout);
        try {
          // Call the passed async function and resolve/reject the promise based on its outcome
          resolve(func(...args));
        } catch (error) {
          reject(error);
        }
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    });
  };
}

const addMissingTextParts = function(distance, pairs) {
  const { startsAt: start, endsAt: end } = distance;
  const result = [];

  // Add provided pairs
  pairs.forEach(pair => {
    result.push(pair);
  });

  // Sort pairs by start value
  pairs.sort((a, b) => a.startsAt - b.startsAt);

  // Split the remaining distance between the pairs
  for (let i = 0; i < pairs.length - 1; i++) {
    const currentPair = pairs[i];
    const nextPair = pairs[i + 1];
    const pairStart = currentPair.endsAt;
    const pairEnd = nextPair.startsAt;
    if (pairStart < pairEnd) {
      result.push({ startsAt: pairStart, endsAt: pairEnd, type: TEXT_TYPE.TEXT });
    }
  }

  // Add remaining distance if any
  if (pairs.length > 0) {
    const firstPair = pairs[0];
    if (firstPair.startsAt > start) {
      result.unshift({ startsAt: start, endsAt: firstPair.startsAt, type: TEXT_TYPE.TEXT });
    }

    const lastPair = pairs[pairs.length - 1];
    if (lastPair.endsAt < end) {
      result.push({ startsAt: lastPair.endsAt, endsAt: end, type: TEXT_TYPE.TEXT });
    }
  } else {
    result.push(distance);
  }

  // Sort the result by start value
  result.sort((a, b) => a.startsAt - b.startsAt);

  return result;
}

export const findStandaloneAsteriskInText = function(text) {
  const result = [];
  const regex = /(?<!\*)\*(?!\*)/g;
  let match;

  while ((match = regex.exec(text)) !== null) {
    // match.index gives the position of the asterisk itself
    result.push({ startsAt: match.index, endsAt: match.index + 1, type: TEXT_TYPE.ASTERISK });
  }

  return result;
}

export function splitText (input) {
  let mergedParts = []

  const total = {startsAt: 0, endsAt: input.length, type: TEXT_TYPE.TEXT}

  const phoneNumberParts = findPhoneNumbersInText(input)
  phoneNumberParts.forEach(item => item.type = TEXT_TYPE.PHONE_NUMBER)
  mergedParts = mergedParts.concat(phoneNumberParts)

  const asteriskParts = findStandaloneAsteriskInText(input)
  mergedParts = mergedParts.concat(asteriskParts)

  mergedParts.sort((a, b) => a.startsAt - b.startsAt);
  mergedParts = addMissingTextParts(total, mergedParts)
  mergedParts.forEach(item => item.value = input.slice(item.startsAt, item.endsAt))

  return mergedParts
}

export function camelCase(str) {
  // Using replace method with regEx
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}
