export const pricingPlanFields = function (isAdminOrSupport) {
  return `
    branding_allowed
    custom_questionnaire_allowed
    employees
    id
    languages
    name
    user_accounts
    training_video
    training_hour
    ${isAdminOrSupport ? `
      plan_variant_name
      plan_variant_id
      current_phase_type
      contract_id
      customer_id
      ` : ''}
  `
}

export const pricingPlan = function (isAdminOrSupport) {
  return `
    pricing_plan {
      ${pricingPlanFields(isAdminOrSupport)}
    }
  `
}

const reportCategoryFields = function (isAdminOrSupport) {
  return `
    id
    label_translations
    display_order
    updated
    ${isAdminOrSupport ? 'is_active' : ''}
    questionnaire {
      questions
      id
      name
    }
    organization_id
    organization {
      name
    }
  `
}

export const organizationConfiguration = `
    organization_configuration {
      dtype
      basic_user_type
      user_roles
      new_organization_types
      manage_users
      manage_reports
      manage_questionnaires
      manage_plan
      manage_own_plan
      manage_organizations
      manage_notifications
      manage_dashboard
      manage_accounts
    }
`

export const organizationFields = function (isWhb, isAdminOrSupport, isLteAdmin) {
  return `
    name
    allow_anonymous_reports
    sign_in_method
    external_identifier
    branding_setup_completed
    id
    logo_filename
    favicon_filename
    color_code_primary
    color_code_secondary
    country
    parent {
      name
      id
      logo_filename
      favicon_filename
      color_code_primary
      color_code_secondary
      country
      parent_id
    }
    default_language
    organization_languages {
      language_locale
    }
    ${organizationConfiguration}
    pricing_plan{
      branding_allowed
      custom_questionnaire_allowed
      training_video
      training_hour
    }
    customer_reference
    start_page_configuration
    start_page_files{
      organization_id
      file_type
      updated
      language_locale
      filename
      id
    }
    ${isWhb ? `report_categories {${reportCategoryFields(false)}}` : ''}
    ${!isWhb ? `
      allow_report_translation
      created
      report_receipt_message
      report_in_review_message
      report_closed_message
      active
      dtype
      primary_contact_email
      primary_contact_phone
      primary_contact_phone_country_code
      primary_contact_first_name
      primary_contact_last_name
      employee_count
      vat_id
      registration_number
      street_address
      city
      parent_id
      parent_organizations {
        organization_id
        level
      }
      child_organizations {
        organization_id: managed_organization_id
        level
      }
      zip_code
      suspended
      billing_page_not_visible
      ${!isLteAdmin ? `report_categories {${reportCategoryFields(isAdminOrSupport)}}` : 'video_views'}
    ` : ''}
    ${isLteAdmin ? `
      invoice_custom_text
    ` : ''}
    ${isAdminOrSupport ? `
      ${pricingPlan(isAdminOrSupport)}
      send_invoices_directly
      alternative_billing_address
      role_assignments {
        role
        lte_user {
          email
          first_name
          last_name
          id
          profile_picture_filename
          organization {
          id
          name
        }
        }
        organization {
          name
        }
      }
      lte_users_aggregate {
        aggregate {
          count
        }
      }
    ` : ''}
  `
}

export const organization = function (isWhb, isAdminOrSupport, isLteAdmin) {
  return `
    organization (order_by: {name: asc}) {
      ${organizationFields(isWhb, isAdminOrSupport, isLteAdmin)}
    }
  `
}

export const pricingPlanTemplate = `
  pricing_plan_template {
    id
    yearly_price
    branding_allowed
    communication_package
    custom_questionnaire_allowed
    customer_service
    employees
    languages
    name
    training_hour
    training_video
    user_accounts
  }
`

export const userFields = function (isOrganizationAdminOrSupport, isTranslatorOrLteAdmin, isCallOperator) {
  return `
    email
    first_name
    id
    last_name
    position
    language
    ${!isCallOperator ? `
    active
      organization {
        id
        name
        logo_filename
        pricing_plan{
          branding_allowed
        }
      }
      role_assignments {
        role
        organization_id
        organization {
          parent_organizations {
            organization_id
            level
          }
          child_organizations {
            organization_id: managed_organization_id
            level
          }
          pricing_plan {
            branding_allowed
            user_accounts
          }
          favicon_filename
          name
        }
      }
    ` : ''}
    lte_user_private {
      mfa_method
      mfa_phone
      mfa_phone_country_code
      setup_completed
      ${isOrganizationAdminOrSupport ? `show_welcome_popup` : ''}
    }
    lte_user_details {
      phone
      phone_country_code
    }
    profile_picture_filename
    title
    ${isOrganizationAdminOrSupport ? `created` : ''}
    ${isTranslatorOrLteAdmin ? `
      lte_user_translator_languages {
        translator_languages_locale
      }` : ''}
  `
}

export const internalNoteFields = function (isLoggedIn) {
  return `
    id
    submitted
    text
    updated
    report_id
    author {
      ${isLoggedIn ? 'id' : 'organization_id'}
    }
  `
}

export const uploadedFileFields = function (isLoggedIn) {
  return `
    id
    file_name
    ${isLoggedIn ? 'internal' : ''}
    chat
    is_whb
    suspicious
    created
    author {
      ${isLoggedIn ? 'id' : 'organization_id'}
    }
  `
}

export const uploadedFiles = function (isLoggedIn) {
  return `
    uploaded_files {
      ${uploadedFileFields(isLoggedIn)}
    }
  `
}

export const chatMessageFields = function (isLoggedIn) {
  return `
    id
    created
    author {
      ${isLoggedIn ? 'id' : 'organization_id'}
    }
    text
    report_id
    is_whb
    translations
  `
}

const reportBaseFields = function (isLoggedIn) {
  return `
    id
    status
    case_id
    category_id
    country
    category_law_type
    submitted
    moved_to_closed
    updated
    report_category {
      id
      label_translations
      display_order
      updated
    }
    ${isLoggedIn ? `
      deletion_requested
      deletion_requested_by_id
      deletion_justification
      deletion_requested_by_user {
        first_name
        last_name
      }
      case_manager {
        id
        email
        first_name
        last_name
        title
      }
      report_permissions {
        permission
        lte_user {
          id
          email
          first_name
          last_name
          title
          organization {
            name
          }
        }
      }
      notifications {
        read
      }
      report_follow_up_actions {
        follow_up_action_id
        text
        updated
      }
    ` : ''}
    organization {
      name
      ${isLoggedIn ? 'allow_report_translation' : ''}
    }
  `
}

export const completeReportFields = function (isLoggedIn) {
  return `
    ${reportBaseFields(isLoggedIn)}
    report_number
    language
    ${isLoggedIn ? `
      case_readers {
        case_readers_id
      }
      is_pin_set
      statements_translations
    ` : `
      requires_pin_change
      whistleblower_email
    `}
    questions
    organization_id
    ${uploadedFiles(isLoggedIn)}
  `
}

export const reportTableFields = function (isLoggedIn) {
  return `
    ${reportBaseFields(isLoggedIn)}
  `
}

export const sortQueryBuilder = (sortingArray) => {
  return sortingArray.map((x) => {
    let val = {}
    let nestedKeys = x.key.split(".")
    val[nestedKeys[nestedKeys.length - 1]] = x.order
    for (let i = nestedKeys.length - 2 ; i >= 0  ; i--){
      val = {[nestedKeys[i]]: val}
    }
    return val
  })
}

export const getOffset = (currentPage, itemsPerPage) => {
  return (currentPage - 1) * itemsPerPage
}

function formatSearchWord(searchWord) {
  return searchWord ? `%${searchWord}%` : '%%'
}

export const getUsersFilterQueryConditions = function (searchWord, accounts, isLteAdmin, organizationIds, withFilterOrganization, withAccountsFilter) {
  const search = formatSearchWord(searchWord)
  const res = {
    _and: [
      {
        _or: [
          {first_name: {_ilike: search}},
          {last_name: {_ilike: search}},
          {email: {_ilike: search}},
          {
            organization: {name: {_ilike: search}}
          },
          {title: {_ilike: search}},
          {position: {_ilike: search}}
        ]
      },
    ]
  }
  if (isLteAdmin && withFilterOrganization) {
    res._and.push({organization_id: {_in: organizationIds}})
  }
  if (withAccountsFilter && !isLteAdmin) {
    res._and.push({_or: [{organization: {id: {_in: accounts}}}, {role_assignments: {organization_id: {_in: accounts}}}]})
  }
  return res
}

export const getOrganizationsFilterQueryConditions = function (isLteAdmin, isAssociatedPartner, searchWord, accounts, parentIds, employeeSearch) {
  const search = formatSearchWord(searchWord)
  const res = {
    _and: [
      {
        _or: [
          {
            name: {_ilike: search}
          },
          {
            dtype: {_ilike: search}
          },
          {
            vat_id: {_ilike: search}
          },
          {
            parent: {
              name: {_ilike: search}
            }
          },
          {
            street_address: {_ilike: search}
          },
          {
            city: {_ilike: search}
          },
          {
            zip_code: {_ilike: search}
          },
          {
            country: {_ilike: search}
          },
          {
            primary_contact_first_name: {_ilike: search}
          },
          {
            primary_contact_last_name: {_ilike: search}
          },
          {
            primary_contact_email: {_ilike: search}
          },
          {
            customer_reference: {_ilike: search}
          },
          {
            employee_count: {_eq: employeeSearch}
          }
        ]
      }

    ]

  }
  if (isAssociatedPartner) {
    res._and.push({_or: [{id: {_in: parentIds}}, {parent_organizations: {organization_id: {_in: parentIds}}}]})
  }
  if (!isLteAdmin) {
    res["id"] = {_in: accounts}
  }
  return res
}
