<template>
  <Dialog
    :model-value="true"
    width="max-content"
    :title="$t('login.welcomeDialog.title')"
    :cancel-text="$t('login.welcomeDialog.notShowAgain')"
    :submit-text="$t('login.welcomeDialog.close')"
    content-class="welcome-dialog"
    @update:modelValue="setShowWelcomePopup(false)"
    @submit="setShowWelcomePopup(false)"
    @cancel="hideWelcomePopup()"
  >
    <div
      class="dialog-content"
      :class="{'law-firm': isLawFirm}">
      <div v-if="isLawFirm" class="item">
        <span class="title-text">
          <v-icon>add_circle_outline</v-icon>
        </span>
        <span class="title-text">{{ $t('login.welcomeDialog.organizations.title') }}</span>
        <p>{{ $t('login.welcomeDialog.organizations.text') }}</p>
        <a @click="$router.push({ name: 'Organizations', query: { addNew: true } }); setShowWelcomePopup(false)">{{ $t('login.welcomeDialog.organizations.action') }}</a>
      </div>
      <div class="item">
        <span class="title-text">
        <v-icon>group</v-icon>
        </span>
        <span class="title-text">{{ $t('login.welcomeDialog.user.title') }}</span>
        <p>{{ $t('login.welcomeDialog.user.text') }}</p>
        <a @click="$router.push({ name: 'Users', query: { addNew: true } }); setShowWelcomePopup(false)">{{ $t('login.welcomeDialog.user.action') }}</a>
      </div>
      <div class="item">
        <span class="title-text">
        <v-icon>content_copy</v-icon>
        </span>
        <span class="title-text">{{ $t('login.welcomeDialog.whistleblowerLink.title') }}</span>
        <p>{{ $t('login.welcomeDialog.whistleblowerLink.text') }}</p>
        <a @click="copyWhbLink()">{{ $t('login.welcomeDialog.whistleblowerLink.action') }}</a>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '../../components/Dialog'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'WelcomeDialog',
  components: { Dialog },
  computed: {
    ...mapGetters(['whbPortalLink', 'isLawFirm', 'currentUserOrganization'])
  },
  methods: {
    ...mapActions(['copyToClipboard', 'hideWelcomePopup']),
    ...mapMutations(['setShowWelcomePopup', 'showDefaultSnackbar']),

    async copyWhbLink () {
      await this.copyToClipboard(this.whbPortalLink(this.currentUserOrganization.id))
      this.showDefaultSnackbar(this.$t('login.welcomeDialog.whistleblowerLink.snackbar'))
    }
  }
}
</script>

<style scoped lang="scss">
  .dialog-content {
    display: grid;
    grid-template-columns: 220px 220px;
    grid-column-gap: 10px;
  }

  .law-firm {
    grid-template-columns: 220px 220px 220px;
  }

  .item {
    display: grid;
    padding: 10px;
    border: 1px solid var(--border-gray);
    grid-template-rows: 50px 55px 1fr 30px;
    border-radius: 5px;
    min-width: 220px;
    min-height: 240px;

    .v-icon {
      color: var(--title-text-color);
      font-size: 34px;
    }

    .title-text {
      text-align: center;
      color: var(--title-text-color);
      font-weight: bold;
    }

    p {
      text-align: center;
    }

    a {
      text-align: center;
      color: var(--accent-color);
    }
  }
</style>

<style lang="scss">
  .welcome-dialog {
    .title h2 {
      text-align: center;
      width: calc(100% + 32px);
    }
  }
</style>
