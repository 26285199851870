
export default {
  canNavigateAccounts: (state, getters) => {
    if (getters.isLteAdmin) {
      return false
    }

    // Check if current user can manage accounts
    if (!state.organizationConfiguration.manage_accounts) {
      return false
    }
    // Check if user has access to multiple organizations
    const userHasRolesInMultipleOrganizations = state.ownAndChildOrganizations?.filter(organization => getters.hasRoleForOrganization(organization.id, ['ADMIN', 'GENERAL_CASE_MANAGER', 'GENERAL_CASE_READER', 'SUPPORT', 'USER'])).length > 1
    return (getters.hasRoleForOrganization(state.userData.organization_id, ['ADMIN']) && state.ownAndChildOrganizations.length > 1) || userHasRolesInMultipleOrganizations
  },
  canNavigateDashboard: (state, getters) => {
    if (getters.isLteAdmin) {
      return false
    }

    // Check if all organizations cannot manage dashboard
    if (getters.organizationsFilter.every(organization => !organization.organization_configuration.manage_dashboard)) {
      return false
    }

    // Check if user has role with access to dashboard in any organization
    return getters.accountsFilterArray.some(orgId => getters.hasRoleForOrganization(orgId, ['ADMIN', 'GENERAL_CASE_MANAGER', 'GENERAL_CASE_READER','REPORTER']) || getters.canManageOrganizationRole(orgId)?.role === 'ADMIN')
  },

  canNavigateReports: (state, getters) => {
    if (getters.isLteAdmin) {
      return false
    }

    // Check if all organizations cannot manage reports
    if (getters.organizationsFilter.every(organization => !organization.organization_configuration.manage_reports)) {
      return false
    }

    // Check if user has role with access to reports in any organization
    return getters.accountsFilterArray.some(orgId => getters.hasRoleForOrganization(orgId, ['ADMIN', 'GENERAL_CASE_MANAGER', 'GENERAL_CASE_READER', 'USER']))
  },

  canNavigateNotifications: (state, getters) => {
    return getters.canNavigateReports
  },

  canNavigateUsers: (state, getters) => {
    return !getters.isCallOperator && !getters.isTranslator
  },

  canNavigateOrganizations: (state, getters) => {
    if (getters.isLteAdmin || getters.isCallOperator) {
      return true
    }

    // Check if current user can manage organizations
    if (!state.organizationConfiguration.manage_organizations) {
      return false
    }

    // Check if single organization is selected
    if (typeof state.accountsFilter === 'string') {
      return false
    }

    // Check if current user has role to manage organizations in own or top organization
    return getters.isOwnOrganizationAdminOrSupport || getters.isTopOrganizationAdminOrSupport
  },

  canNavigateAccountSettings: (state, getters) => {
    return getters.canNavigateOrganizationSettings || getters.canNavigateUserSettings
  },

  canNavigateOrganizationSettings: (state, getters) => {
    if (getters.isLteAdmin) {
      return false
    }

    // Check if current user has role to manage organizations in own or top organization
    return getters.isOwnOrganizationAdminOrSupport || getters.isTopOrganizationOrParentOrganizationAdminOrSupport
  },

  canNavigateUserSettings: (state, getters) => {
    return !getters.isCallOperator
  },

  canNavigateCustomizations: (state, getters) => {
    return getters.canNavigateNotificationCustomizations || getters.canNavigateQuestionnaireCustomizations
  },

  canNavigateQuestionnaireCustomizations: (state, getters) => {
    // Check if all organizations cannot manage questionnaires
    if (getters.organizationsFilter.every(organization => !organization.organization_configuration.manage_questionnaires)) {
      return false
    }

    if (!getters.isAnyOrganizationAdminOrSupport) {
      return false
    }

    return getters.canCustomizeQuestionnaire
  },

  canNavigateStartPageCustomizations: (state, getters) => {
    return getters.isAnyOrganizationAdminOrSupport
  },

  // Can manage Start Page Content and FAQ & Custom Links
  canManageStartPageAdvanced: (state, getters) => {
    // The same requirements as navigate questionnaire navigation
    return getters.canNavigateQuestionnaireCustomizations
  },

  canNavigateNotificationCustomizations: (state, getters) => {
    return getters.isAnyOrganizationAdminOrSupport
  },

  isAnyOrganizationAdminOrSupport: (state, getters) => {
    if (getters.isGlobalUser) {
      return false
    }

    // Check if current user has role in own or top organization
    return getters.isOwnOrganizationAdminOrSupport || getters.isTopOrganizationOrParentOrganizationAdminOrSupport
  },

  canNavigatePricingPlan: (state, getters) => {
    if (getters.disableBillwerk) {
      return false
    }

    // Check if all organizations not billwerkable
    if (getters.organizationsFilter.every(organization => !getters.isOrganizationBillwerkable(organization))) {
      return false
    }

    // Check if all organizations cannot manage plan
    if (getters.organizationsFilter.every(organization => !organization.organization_configuration.manage_plan)) {
      return false
    }

    // Check if current user has none role in own or top organization
    if (!getters.isOwnOrganizationAdminOrSupport && !getters.isTopOrganizationAdminOrSupport) {
      return false
    }

    return !(state.pricingPlanExpires && state.accountsFilter !== getters.currentUserOrganization.id) || Array.isArray(state.accountsFilter)
  }
}
