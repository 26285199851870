export default {
  setNotifications (state, notifications) {
    state.notifications = notifications
  },
  setUnreadNotifications (state, unreadNotifications) {
    state.unreadNotifications = unreadNotifications
  },
  setNotificationOffset (state, offset) {
    state.notificationOffset = offset
  },
  addNotifications (state, notifications) {
    state.notifications = [...state.notifications, ...notifications]
  },
  setNotificationLength (state, notificationLength) {
    state.notificationLength = notificationLength
  }
}
