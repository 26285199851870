<template>

  <LoadingScreen :modelValue="modelValue" v-if="isLoading"></LoadingScreen>
  <Dialog :cancel-text="$t(`reportOverview.download.cancel`)"
          :submit-text="$t(`reportOverview.download.submit`)"
          :text="$t(`reportOverview.download.${multiple?'multiple':'singular'}.confirmationText`)"
          :title="$t(`reportOverview.download.${multiple?'multiple':'singular'}.title`)"
          :disableSubmit="selectedReportIds && selectedReportIds.length===0"
          @cancel="hide()"
          @submit="downloadReports(selectedReportIds)"
          @update:modelValue="hide()"
          v-else
          :modelValue="modelValue"
  >
    <template v-slot:anotherButton>
      <v-tooltip :disabled='false' bottom>
        <template v-slot:activator="{ on }">
          <AwaitButton
            :on="on"
            style="margin-bottom: 5px"
            :color="'primary'"
            :custom-loading="false"
            :disabled="allReportIds && allReportIds.length===0"
            emit-click
            @click="downloadReportsOverview()"
          >{{ $t('reportOverview.download.register') }}</AwaitButton>
        </template>
        <span>{{$t('reportOverview.download.registerTooltip', {reportsCount: allReportIds ? allReportIds.length : 0})}}</span>
      </v-tooltip>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '../../../components/Dialog'
import { mapActions } from 'vuex'
import LoadingScreen from '@/components/LoadingScreen'
import AwaitButton from '@/components/AwaitButton'

export default {
  name: 'DownloadReportsDialog',
  data () {
    return {
      isLoading: false
    }
  },
  components: { AwaitButton, LoadingScreen, Dialog },
  props: {
    modelValue: {
      type: Boolean
    },
    selectedReportIds: {
      type: Array
    },
    multiple: {
      type: Boolean,
      default: false
    },
    allReportIds: {
      type: Array
    },
    accountsFilter: {
      type: Array
    },
    reportsTableOptions: {
      type: Object
    },
    statusOptions: {
      type: Array
    },
    categories: {
      type: Array
    }
  },
  methods: {
    ...mapActions(['download', 'downloadOverview']),
    async downloadReports (selectedReportIds) {
      this.isLoading = true
      await this.download(selectedReportIds).then((response) => {
        response.blob().then((blob) => {
          const fileName = response.headers.get('Content-Disposition').split('filename=')[1].split('"')[1]
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }).finally(() => {
          this.hide()
        })
      }).catch(() => this.hide())
    },
    async downloadReportsOverview () {
      this.isLoading = true
      await this.downloadOverview({ reportIds: this.allReportIds, accountsFilter: this.accountsFilter, reportsTableOptions: this.reportsTableOptions, statusOptions: this.statusOptions, categories: this.categories }).then((response) => {
        response.blob().then((blob) => {
          const fileName = response.headers.get('Content-Disposition').split('filename=')[1].split('"')[1]
          if (fileName.match(/^.*\.pdf$/g)) {
            window.open(URL.createObjectURL(blob))
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
        }).finally(() => {
          this.hide()
        })
      }).catch(() => {
        this.hide()
      })
    },
    hide () {
      this.isLoading = false
      this.$emit('hide-child')
    }
  }
}
</script>
