<template>
  <v-list density="compact" style='padding-top:0;margin-top:-1px;'>
    <v-list-item v-if='!miniVariant' key="manageSeparator" title="">
      <v-list-item-title>{{ $t('navigation.manage') }}</v-list-item-title>
    </v-list-item>
    <NavigationItem
      v-if="canNavigateUsers"
      item-key="users"
      route-name="Users"
      icon-name="group"
      title-label="navigation.users"
    />

    <NavigationItem
      v-if="canNavigateOrganizations"
      item-key="organizations"
      route-name="Organizations"
      icon-name="list"
      title-label="navigation.organizations"
    />

    <NavigationItem
      v-if="canNavigateAccountSettings"
      item-key="settings"
      :route-name="canNavigateOrganizationSettings ? 'SmeSettings' : 'UserSettings'"
      icon-name="settings"
      title-label="navigation.account"
    />

    <NavigationItem
      v-if="canNavigateCustomizations"
      item-key="customizations"
      :route-name="canNavigateQuestionnaireCustomizations ? 'StartPageSettings' : 'NotificationSettings'"
      icon-name="tune"
      title-label="navigation.customizations"
    />

    <NavigationItem
      v-if="canNavigatePricingPlan"
      item-key="plan"
      route-name="PlanSettings"
      icon-name="widgets"
      title-label="navigation.plan"
    />

    <NavigationItem
      v-if="isLteAdmin"
      item-key="LanguageTexts"
      route-name="LanguageTexts"
      icon-name="language"
      title-label="navigation.languageTextsOverview"
    />

    <NavigationItem
      v-if="isLteAdmin || isTranslator"
      item-key="TranlationEditor"
      route-name="TranslationEditor"
      icon-name="translate"
      title-label="navigation.languageTexts"
    />

  </v-list>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import NavigationItem from './NavigationItem'

export default {
  name: 'NavigationListSettings',
  components: { NavigationItem },
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant
    }),
    ...mapGetters([
      'canNavigateUsers',
      'canNavigateOrganizations',
      'canNavigateAccountSettings',
      'canNavigateOrganizationSettings',
      'canNavigateCustomizations',
      'canNavigateQuestionnaireCustomizations',
      'canNavigatePricingPlan',
      'isLteAdmin',
      'isTranslator'
    ])
  }
}
</script>
