<template>
  <div>
    <v-tooltip v-if='miniVariant && canNavigateAccounts' right key='miniAccountSwitcher'>
      <template v-slot:activator="{ props : toolProps }">
        <v-menu max-height='400px'>
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="{...props, ...toolProps}" title="">
                <v-img :src="miniAccountSelectorIcon" v-if="miniAccountSelectorIcon!==null" ></v-img>
                <v-icon v-else>account_box</v-icon>
              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </template>

          <v-list density="compact">
            <div
              v-for="(item, i) in filteredAccountItems"
              :key="i"
            >

              <v-list-subheader v-if="item.divider && item.header">
                {{ item.header}}
              </v-list-subheader>
              <v-divider v-else-if="item.divider" class="mt-2"></v-divider>

              <v-list-item v-else-if="item.value" :style="{'padding-left': item.dtype === 'Subsidiary' ? '15px' : '0'}"
                           title=""
                           @click="el => {account= item.value ;saveAccount()}" :class='item.value===account ? "v-list-item--active":""'
              >

                <div class="list-item"  style="padding-left:16px" :style="item.showImageInSelectMenu == false?'grid-template-columns: 130px 42px':''">
                  <div class="list-item-icon" v-if="item.showImageInSelectMenu != false">
                    <v-img  :src="item.image" max-width="30px"></v-img>
                  </div>
                  <div class="list-item-content">
                    {{ item.text }}
                  </div>
                </div>

              </v-list-item>
            </div>
          </v-list>
        </v-menu>
      </template>
      <label v-if='!accountSelected'>
        {{$t('navigation.accountSwitcher.text')}}
        {{accountSelected}}
      </label>
      <label v-else>
        <div>
          {{accountSelected.text}}
        </div>
        <div>
          {{$t('navigation.mandateReference.label')}} : {{organizationTopAccount.customer_reference}}
        </div>
      </label>
    </v-tooltip>

    <v-list-item v-if="!miniVariant && canNavigateAccounts" key='accountSwitcher' title="">
      <v-select :items="filteredAccountItems" class="account-switcher" density="compact" hide-details variant="outlined"
                :menu-props="{closeOnClick: true, closeOnContentClick: true}"
                style="margin-right: 10px" :modelValue="account">
        <template v-slot:selection="{ item : {props : { title }}}">
          <div class="list-item">
            <div class="list-item-icon">
              <v-img :src="title.image" max-width="30px" v-if="title.showImage || title.showImageSelected"></v-img>
              <v-icon v-else>account_box</v-icon>
            </div>
            <div class="list-item-content">
              {{ title.text }}
            </div>
          </div>
        </template>

    <template v-slot:item="{ item : {props : { title }}}">
      <v-list-subheader v-if="title.divider && title.header">
        {{ title.header}}
      </v-list-subheader>
      <v-divider v-else-if="title.divider" class="mt-2"></v-divider>

      <v-list-item v-else-if="title.value" :style="{'padding-left': title.dtype === 'Subsidiary' ? '15px' : '0'}"
                   title=""
           @click="el => {account= title.value ;saveAccount()}" :class='title.value===account ? "v-list-item--active":""'
      >
        <div class="list-item"  style="padding-left:16px" :style="title.showImageInSelectMenu == false?'grid-template-columns: 130px 42px':''">
          <div class="list-item-icon" v-if="title.showImageInSelectMenu != false">
            <v-img :src="title.image" max-width="30px"></v-img>
          </div>
          <div class="list-item-content">
            {{ title.text }}
          </div>
        </div>

      </v-list-item>
        </template>
      </v-select>
    </v-list-item>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'AccountSwitcher',
  computed: {
    ...mapState({
      miniVariant: state => state.miniVariant,
      accounts: state => state.accountsFilter,
      userData: state => state.userData,
      ownAndChildOrganizations: state => state.ownAndChildOrganizations
    }),
    ...mapGetters([
      'currentUserOrganization',
      'canNavigateAccounts',
      'canNavigateDashboard',
      'canNavigateReports',
      'canNavigateQuestionnaireCustomizations',
      'hasRoleForOrganization',
      'hasRoleForOwnOrganization',
      'hasRoleForOrganizationOrParentOrganization',
      'organizationTopAccount'
    ]),
    account: {
      get () {
        return [this.accounts]
      },
      set (accounts) {
        this.setAccounts(accounts)
      }
    },

    accountItems () {
      const allowedOrganizations = this.ownAndChildOrganizations.filter(organization => {
        return this.hasRoleForOrganization(organization.id, ['ADMIN', 'GENERAL_CASE_READER', 'GENERAL_CASE_MANAGER', 'SUPPORT', 'USER']) ||
          this.hasRoleForOrganizationOrParentOrganization(organization.id, ['ADMIN'])
      })
      let items = allowedOrganizations.map(organization => ({
        showImage: true,
        image: `/organization/${organization.id}/favicon_${organization.favicon_filename}`,
        value: organization.id,
        text: organization.name,
        dtype: organization.dtype,
        parent_id: organization.parent_id
      }))

      // Store result based on the parent-child relation
      const result = []
      if (this.userData.organization_id && allowedOrganizations.some(org => org.id === this.userData.organization_id)) {
        // Move ownOrganization to the result
        const currentUserOrganizationIndex = items.findIndex(x => x.value === this.userData.organization_id)
        result.push(items[currentUserOrganizationIndex])
        items.splice(currentUserOrganizationIndex, 1)
      }

      // Move all organization that parent is not present on the allowedOrganizations list to the result
      for (let index = items.length - 1; index >= 0; index--) {
        if (!allowedOrganizations.some(org => org.id === items[index].parent_id)) {
          result.push(items[index])
          items.splice(index, 1)
        }
      }

      items = items.reverse()
      let currentIndex = 0

      // Find all children organization for parent organization on currentIndex
      while (currentIndex < result.length) {
        const childIndex = items.findIndex(x => x.parent_id === result[currentIndex].value)

        if (childIndex !== -1) {
          // If child found, move this child next after the parent organization to the result
          result.splice(currentIndex + 1, 0, items[childIndex])
          items.splice(childIndex, 1)
        } else {
          // Move currentIndex to the next parent organization
          currentIndex++
        }
      }

      // Add sme with subsidiaries groups
      const ownOrganization = this.ownAndChildOrganizations.find(org => org.id === this.userData.organization_id)
      if (ownOrganization.dtype !== 'Sme' && ownOrganization.dtype !== 'Subsidiary') {
        const mapSmeToSubsidiaries = {}
        for (const item of result) {
          if (item.dtype === 'Subsidiary' && item.parent_id in mapSmeToSubsidiaries) {
            mapSmeToSubsidiaries[item.parent_id].push(item.value)
          } else if (item.dtype === 'Subsidiary') {
            mapSmeToSubsidiaries[item.parent_id] = [item.parent_id, item.value]
          }
        }

        for (const smeId in mapSmeToSubsidiaries) {
          const smeIndex = result.findIndex(item => item.value === smeId)
          if (smeIndex !== -1) {
            const itemSmeAll = Object.assign({}, result[smeIndex], { value: mapSmeToSubsidiaries[smeId] })
            itemSmeAll.text = `${itemSmeAll.text} - ${this.$t('translationTable.keyFilterOptions.all')}`
            result.splice(smeIndex, 0, itemSmeAll)
          }
        }
      }

      result.unshift({ header: this.$t('navigation.accountSwitcher.clientAccounts'), divider: true })
      result.unshift({ divider: true })
      if (this.hasRoleForOwnOrganization(['ADMIN', 'GENERAL CASE READER', 'GENERAL CASE MANAGER', 'SUPPORT', 'USER'])) {
        // eslint-disable-next-line camelcase
        result.unshift({
          showImageSelected: true,
          image: `/organization/${this.userData.organization_id}/favicon_${ownOrganization.favicon_filename}`,
          // eslint-disable-next-line camelcase
          value: this.userData?.organization_id,
          showImageInSelectMenu : false,
          text: this.$t('navigation.accountSwitcher.ownAccount')
        })
      }

      const allAccounts = allowedOrganizations
        .map(organization => organization.id)
        .sort()
      if (ownOrganization && allAccounts.includes(ownOrganization.id)) {
        allAccounts.splice(allAccounts.indexOf(ownOrganization.id), 1)
        allAccounts.unshift(ownOrganization.id)
      }
      result.unshift({
        value: allAccounts,
        showImageInSelectMenu : false,
        text: this.$t('navigation.accountSwitcher.allAccounts')
      })
      return result
    },

    filteredAccountItems () {
      return this.accountItems.filter((acc, i) => i < 3 || acc.value !== this.currentUserOrganization.id)
    },

    accountSelected () {
      if (typeof this.account[0] === 'string') {
        return this.accountItems.find(item => item.value === this.account[0])
      }
      return null
    },

    miniAccountSelectorIcon () {
      const account = this.accountSelected
      if (account && (account.showImage || account.showImageSelected)) return account.image
      return null
    }
  },
  methods: {
    ...mapActions([
      'loadMetrics',
      'loadAllReportsFiltered',
      'loadCaseManagerOptions',
      'loadCategoryOptions',
      'loadFollowUpActionOptions',
      'loadQuestionnaireTemplates',
      'loadOrganizationsFiltered',
      'loadAllUsersFiltered',
      'updateColorCodes'
    ]),
    ...mapMutations([
      'setAccounts',
      'setSelectedReports'
    ]),
    async saveAccount () {
      this.updateColorCodes()
      if (this.canNavigateDashboard) {
        this.loadMetrics()
      }
      if (this.canNavigateReports) {
        await this.loadAllReportsFiltered()
        await this.loadCaseManagerOptions()
        this.setSelectedReports([])
        await this.loadCategoryOptions()
        await this.loadFollowUpActionOptions()
      }
      if (this.canNavigateQuestionnaireCustomizations) {
        await this.loadQuestionnaireTemplates()
      }
      await this.loadOrganizationsFiltered()
      await this.loadAllUsersFiltered()
      if (['Organizations', 'ViewReportSME'].includes(this.$route.name)) {
        this.$router.push({
          name: 'SMEReports',
          params: {
            companyHash: this.userData.organization_id
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(hr) {
  border-color: var(--border-gray) !important;
}

:deep(.v-subheader) {
  height: 16px !important;
  padding-left: 16px !important;
  margin-top: 15px;
}

:deep(.v-list-item) {
  * {
    color: var(--title-text-color);
  }

  .v-list-item__action {
    margin: 0;
  }

  .v-btn {
    padding: 0 6px !important;
  }

  &:before {
    transition: none !important;
  }
}

:deep(.v-list-item-title) {
  font-size: 14px !important;
  font-weight: bold !important;
}

.v-list-item--active {
  * {
    color: var(--accent-color) !important;
  }

  &:before {
    width: 5px;
    height: 25px;
    background-color: var(--accent-color);
    content: '';
    position: absolute;
    left: 5px;
    top: 7px;
    opacity: 1 !important;
    border-radius: 3px;
  }
}

.account-switcher {
  padding: 5px;

  .v-list-item {
    overflow: hidden;
  }
}

.account-switcher.v-input--dense > .v-input__control > .v-input__slot .v-input__append-inner {
  margin-top: 12px !important;
}
</style>
