import apollo from '../../apollo'
import gql from 'graphql-tag'

export default {

  async triggerMailNotifications ({ dispatch }, timestamp) {
    await dispatch('POST', {
      url: `/rest/scheduler/trigger-status-update?now=${timestamp}`
    })
  },

  subscribeToNotifications ({ state, commit, getters, dispatch }) {
    if (getters.isTranslator) {
      console.warn('Translators should not attempt loading notifications')
      return
    }

    if (state.notificationSubscription) {
      state.notificationSubscription.unsubscribe()
    }

    const observer = (apollo(state).subscribe({
      query: gql`
        subscription notification {
          notification(limit: 21, order_by: {created: desc}) {
            read
            created
            id
            template_key
            report {
              id
              case_id
              organization {
                id
                name
              }
            }
          }
        }
      `
    }))

    const subscription = observer.subscribe({
      next (data) {
        commit('setNotificationOffset', 20)
        commit('setNotificationLength', data.data.notification.length)

        if (data.data.notification.length > 20) {
          data.data.notification.pop()
        }

        commit('setNotifications', data.data.notification)
      },
      error (error) {
        console.error('subscription error', error)
      }
    })

    dispatch('subscribeToUnreadNotificationsCount')
    commit('setNotificationSubscription', subscription)
  },

  async loadMoreNotifications ({ state, commit }) {
    const response = await apollo(state).query({
      query: gql`
        query notification {
          notification(offset: ${state.notificationOffset}, limit: 20, order_by: {created: desc}) {
            read
            created
            id
            template_key
            report {
              id
              case_id
              organization {
                id
                name
              }
            }
          }
          notification_aggregate {
            aggregate {
              count
            }
          }
        }
      `
    })

    commit('addNotifications', response.data.notification)
    commit('setNotificationLength', response.data.notification_aggregate.aggregate.count)
    commit('setNotificationOffset', state.notificationOffset + 20)
  },

  async subscribeToUnreadNotificationsCount ({ state, commit }) {
    if (state.unreadNotificationsSubscription) {
      state.unreadNotificationsSubscription.unsubscribe()
    }

    const observer = (apollo(state).subscribe({
      query: gql`
        subscription notification_aggregate {
          notification_aggregate(where: {read: {_eq: false}}) {
            aggregate {
              count
            }
          }
        }
      `
    }))

    const subscription = observer.subscribe({
      next (data) {
        commit('setUnreadNotifications', data.data.notification_aggregate.aggregate.count)
      },
      error (error) {
        console.error('subscription error', error)
      }
    })

    commit('setUnreadNotificationsSubscription', subscription)
  },

  async markPlanExpiryNotificationAsRead ({ state, commit, dispatch }, notification) {
    if (notification.report) {
      return
    }
    await dispatch('POST', {
      url: `/rest/user/mark-plan-expiry-notification-read?notificationId=${notification.id}`
    })
  }

}
