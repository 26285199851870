export default {

  async copyToClipboard ({ state }, value) {
    if (!navigator.clipboard) {
      const el = document.createElement('textarea')
      el.innerText = value // required due to a bug in chrome that breaks copy on local dev server when using alias dns to localhost.
      el.value = value
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    } else {
      await navigator.clipboard.writeText(value)
    }
    return true
  },

  changeFavicon ({ state, getters }) {
    const favicon = document.getElementById('favicon')

    let faviconFileName = ''
    if (state.organizations) {
      const organization = state.organizations.find(org => org.id === state.currentOrganizationId)
      faviconFileName = organization ? organization.favicon_filename : ''
      if (faviconFileName === null) {
        // eslint-disable-next-line camelcase
        const parent = state.organizations.find(org => org.id === getters.currentUserOrganization?.parent?.id || getters.currentUserOrganization?.parent_id)
        faviconFileName = parent ? parent.favicon_filename : ''
      }
    }

    if (faviconFileName) {
      favicon.href = `/organization/${state.currentOrganizationId}/favicon_${faviconFileName}`
    }
  }

}
