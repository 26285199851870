export default {

  async loadInvoices ({ commit, dispatch, getters }) {
    let query = ''
    if (getters.invoiceDateFilterSorted.length) {
      query = getters.invoiceDateFilterSorted.length === 1
        ? `?date-from=${getters.invoiceDateFilterSorted[0]}&date-to=${getters.invoiceDateFilterSorted[0]}`
        : `?date-from=${getters.invoiceDateFilterSorted[0]}&date-to=${getters.invoiceDateFilterSorted[1]}`
    }

    const response = await dispatch('GET', {
      url: `/rest/billwerk/invoices${query}`
    })

    const invoices = await response.json()
    commit('setInvoices', invoices)
  }

}
