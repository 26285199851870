<template>
  <v-app v-if="show">
    <router-link
      v-if="['Report', 'AccessReport'].includes($route.name)"
      class="sr-only"
      :aria-label="$t('general.goToMainContent.text')"
      to="#report-dashboard-main-content">
      {{$t('general.goToMainContent.text')}}
    </router-link>
    <Dialog
      :model-value="showEmailAlreadyExists"
      :title="$t('users.emailAlreadyExistsDialog.title')"
      :text="$t('users.emailAlreadyExistsDialog.text')"
      :submit-text="$t('users.emailAlreadyExistsDialog.submit')"
      @cancel="showEmailAlreadyExists = false"
      @submit="showEmailAlreadyExists = false"
      @update:modelValue="showEmailAlreadyExists = false"
    />

    <WelcomeDialog
      v-if="
        showWelcomePopup &&
        ![
          'SetupMfa',
          'MfaSetup',
          'Login',
          'Default404',
          'Signup',
          'SignupPlan',
          'SignupConfirmation',
          'SetupLogin',
          'SetupPasswordReset',
          'SetupBranding',
        ].includes($route.name)
      "
    ></WelcomeDialog>

    <Dialog
      :cancel-text="
        isWhb
          ? $t('general.sessionExpiredDialog.whistleblower.cancel')
          : $t('general.sessionExpiredDialog.cancel')
      "
      :submit-text="
        isWhb
          ? $t('general.sessionExpiredDialog.whistleblower.submit')
          : $t('general.sessionExpiredDialog.submit')
      "
      :text="
        isWhb
          ? $t('general.sessionExpiredDialog.whistleblower.text')
          : $t('general.sessionExpiredDialog.text')
      "
      :title="$t('general.sessionExpiredDialog.title')"
      @cancel="handleCancel"
      @submit="handleSubmit"
      hide-close
      v-model="sessionExpired"
    ></Dialog>
    <Dialog
      v-if="
        userData &&
        userData.setup_completed &&
        currentUserOrganization &&
        currentUserOrganization.branding_setup_completed
      "
      :cancel-text="$t('general.planExpires.cancel')"
      :submit-text="$t('general.planExpires.submit')"
      :text="$t('general.planExpires.text')"
      :title="$t('general.planExpires.title')"
      @submit="
        setPlanExpires(false);
        $router.push({ name: 'PlanSettings' });
      "
      @cancel="setPlanExpires(false)"
      hide-close
      :model-value="planExpires"
    >
      <div class="d-inline-flex">
        <v-icon x-large color="warning">warning</v-icon>
        <p
          style="min-height: 25px; margin-left: 10px"
          v-dompurify-html="$t('general.planExpires.text')"
        ></p>
      </div>
    </Dialog>
    <DownloadReportsDialog
      :report-ids="[currentReportId]"
      :modelValue="showDownloadReportDialog"
      @hide-child="showDownloadReportDialog = false"
      ref="DownloadReportsDialog"
    />
    <LoginNavigation
      v-if="
        !($vuetify.display.width <= 1000) &&
        ((userData && !userData.setup_completed) ||
          [
            'Cookies',
            'Login',
            'SignupPayment',
            'GeneralLogin',
            'Default404',
            'Signup',
            'SignupPlan',
            'SignupConfirmation',
            'SetupLogin',
            'SetupPasswordReset',
            'SetupBranding',
            'Report',
            'AccessReport',
            'Faq',
            'CustomLegalNotice',
            'CustomDataProtection',
            'CustomAccessibilityDeclaration',
          ].includes($route.name) ||
          (userData && userData.requires_password_change))
      "
    ></LoginNavigation>

    <Navigation
      :showMailTrigger="showMailTrigger"
      @showMailTriggerDialog="showMailTriggerDialog = true"
      v-else-if="
        !$vuetify.display.xs &&
        !isWhb &&
        ![
          'Login',
          'Default404',
          'Signup',
          'SignupPlan',
          'SignupConfirmation',
          'SetupLogin',
          'SetupLanguageReset',
          'SetupPasswordReset',
          'SetupBranding',
          'NewReport',
          'Imprint',
          'DataProtection',
          'Faq',
          'PasswordReset',
          'LanguageReset',
          'MfaSetup',
          'SetupMfa'
        ].includes($route.name)
      "
    />
    <MobileWhbNavigation
      @update:modelValue="(v) => (showMenu = v)"
      @downloadReport="downloadReport()"
      v-if="showMenu && isWhb && $vuetify.display.width <= 750"
    />
    <MailTrigger v-model="showMailTriggerDialog" />
    <v-snackbar
      :color="errorMessage === '' ? 'error' : 'warning'"
      :model-value="showDefaultError"
      @update:modelValue="
        hideDefaultError();
        resetErrorMessage();
      "
      app
      timeout="-1"
      location="top"
    >
      <b>{{ $t("general.defaultError.title") }}</b
      ><br />
      <span
        class="error-text"
        v-dompurify-html="$t('general.defaultError.text', { supportEmail: supportEmail })"
        v-if="errorMessage === ''"
      ></span>
      <span class="error-text" v-else>{{ errorMessage }}</span>

      <template v-slot:actions>
        <v-icon @click="hideDefaultError()">close</v-icon>
      </template>
    </v-snackbar>

    <v-snackbar
      v-for="(snackbar, index) in defaultSnackbars"
      :key="snackbar.id"
      color="primary"
      :modelValue="snackbar.show"
      app
      location="top"
      @update:modelValue="hideDefaultSnackbar(index)"
      :style="{ top: calcMargin(snackbar.pos), transition: '0.5s' }"
    >
      <span>{{ snackbar.text }}</span>
      <template v-slot:actions>
        <v-icon @click="hideDefaultSnackbar(index)">close</v-icon>
      </template>
    </v-snackbar>

    <v-app-bar
      app
      v-if="appRequest.showAppBar"
      :style="{
        left: isLoginOrReportPage() && $vuetify.display.width >= 1000? '400px' : '',
        height: calculateHeaderHeight(),
        width: calculateHeaderWidth()
      }"
    >
      <h1
        v-if="appRequest.appBarTitle"
        :style="
          $vuetify.display.width <= 1000
            ? 'font-size: 1.15em'
            : 'font-size: 2.15em'
        "
      >
        {{ $t(appRequest.appBarTitle) }}
      </h1>
      <div v-else>
        <a
          v-if="!!currentOrganizationId"
          role="button"
          aria-label="Back to main page"
          :class="{
            disabled:
              $route.name === 'Default404' ||
              $route.name === 'Login' ||
              $route.name === 'GeneralLogin' ||
              showPinPage() ||
              showIdPage(),
          }"
          @click="$router.push({ name: 'Report' })"
        >
          <img
            :key="
              currentOrganizationId
                ? `/organization/${currentOrganizationId}/logo${logoFileName}`
                : ''
            "
            :src="
              currentOrganizationId
                ? `/organization/${currentOrganizationId}/logo${logoFileName}`
                : ''
            "
            :class="$vuetify.display.width > 1000 ? 'logo' : 'logo-small'"
            alt="Organization Logo"
            style="margin-top: 7px"
          />
        </a>
      </div>

      <h1
        :class="{
          'title-text': $vuetify.display.width > 1000,
          'title-text-small': $vuetify.display.width <= 1000,
        }"
        v-if="$vuetify.display.width > 750"
      >
        {{ $t("general.whistleblowerSystem.title") }}
      </h1>

      <v-spacer></v-spacer>

      <div
        :class="{ selectFocussed }"
        class="test-features"
        v-if="$route.name !== 'ViewReportWHB' && $vuetify.display.width > 650"
      >
        <button v-if="showMailTrigger" @click="showMailTriggerDialog = true">
          {{ $t("mailTrigger.toggle") }}
        </button>
        <button
          @click="$router.push({ name: 'GeneralLogin' })"
          v-if="!userData"
        >
          Anmelden
        </button>
      </div>
      <template v-if="$vuetify.display.width > 750">
        <div
          v-if="
            currentUserOrganization &&
            currentUserOrganization.customer_reference
          "
          style="padding-right: 20px"
        >
          {{ $t("reportDetails.smeHeading.mandateReference.label") }} :
          {{ currentUserOrganization.customer_reference }}
        </div>
        <div v-if="isWhb && $route.name === 'ViewReportWHB'">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <span style="width: 40px !important">
                <v-btn
                  @click="
                    reportId = currentReport.id;
                    downloadReport();
                  "
                  class="download-reports-btn"
                  variant="outlined"
                  text
                  aria-label="Download Report"
                  v-bind="props"
                >
                  <v-icon>save_alt</v-icon>
                </v-btn>
              </span>
            </template>
            <label>{{ $t(`reportDetails.smeHeading.downloadReport`) }}</label>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn
                style="
                  width: 40px !important;
                  margin-left: 10px;
                  margin-right: 10px;
                "
                @click="logout"
                class="download-reports-btn"
                v-bind="props"
                variant="text"
                aria-label="Log out"
              >
                <v-icon>logout</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("general.logoutTooltip") }}</span>
          </v-tooltip>
        </div>
        <LanguageSelection
          @update:modelValue="
            (ev) => changeUserLanguage({ language: ev, save: false })
          "
          v-if="appRequest.showAppBarLanguageSelection"
          :force-language-options="forcedLanguages"
        />
      </template>
      <div v-else>
        <LanguageSelection
          @update:modelValue="
            (ev) => changeUserLanguage({ language: ev, save: false })
          "
          v-if="appRequest.showAppBarLanguageSelection"
          :force-language-options="forcedLanguages"
        />
      </div>
    </v-app-bar>

    <v-main
      :style="{
        paddingLeft: !($vuetify.display.width <= 1000) && isLoginOrReportPage() ? '400px' : null,
        paddingTop: calculateHeaderHeight(),
      }"
    >
      <v-overlay
        ::model-value="showDefaultLoading"
        style="z-index: 200 !important"
      >
        <v-progress-circular
          :size="100"
          color="primary"
          indeterminate
          v-if="showDefaultLoading"
        ></v-progress-circular>
      </v-overlay>
      <scroll-provider v-if="appRequest.horizontalScrolls" />
      <router-view :key="key" v-if="!show404Page"></router-view>
      <Not-found v-else></Not-found>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Navigation from "./components/Navigation";
import MailTrigger from "./components/MailTrigger";
import NotFound from "@/views/notFound/NotFound";
import LoginNavigation from "@/components/LoginNavigation";
import WelcomeDialog from "./views/login/WelcomeDialog";
import Dialog from "@/components/Dialog";
import DownloadReportsDialog from "@/views/report/components/DownloadReportsDialog";
import LanguageSelection from "./components/LanguageSelection";
import MobileWhbNavigation from "./components/MobileWhbNavigation";
import ScrollProvider from "./components/ScrollProvider";
import router from "./router";
import i18next from "i18next";
import {ref} from "vue";
import debounce from 'debounce'

export default {
  name: "App",
  components: {
    MobileWhbNavigation,
    LanguageSelection,
    Dialog,
    WelcomeDialog,
    MailTrigger,
    Navigation,
    NotFound,
    LoginNavigation,
    DownloadReportsDialog,
    ScrollProvider,
  },
  async created() {
    await this.initializeI18nLanguage(navigator.language.slice(0, 2));
    await this.loadLanguage({ locales: ['en', 'de'] });
    i18next.on("languageChanged", () => {
      const lang = i18next.language;
      this.loadLanguage({ locales: [lang], reload: true });
      this.changeLanguage(lang);
    });
    await this.loadRoles();
    window.addEventListener("message", async (event) => {
      if (event.data === "LTE_LOGIN_RESTORED") {
        await this.loadUserData({ sessionExpired: true });

        if (this.isGraphQlError) {
          this.key = Date.now();
          this.setIsGraphQlError(false);
        }
      }
    });
    try {
      await this.loadUserData();

      if (
        this.currentOrganizationId &&
        this.currentOrganizationId !== "admin"
      ) {
        await this.addCurrentOrganizationToSession();
      }

      if (!this.$route.meta.permitAll && !this.userData) {
        await this.$router.push({
          name: "Login",
          query: {
            redirectTo: this.$route.fullPath,
          },
        });
      }

      if (
        this.userData &&
        !this.userData.requires_password_change &&
        ["Login", "GeneralLogin", "Default404"].includes(this.$route.name)
      ) {
        if (this.isLteAdmin) {
          this.$router.push({
            path: "/admin/users/",
          });
        } else if (this.isSupport) {
          this.$router.push({
            name: "Users",
            params: { companyHash: this.userData.organization_id },
          });
        } else if (this.isCallOperator) {
          this.$router.push({
            path: "admin/organizations/",
          });
        } else if (this.isTranslator) {
          this.$router.push({
            name: "TranslationEditor",
            params: { companyHash: "admin" },
          });
        } else {
          this.$router.push({
            path: `/${this.userData.organization_id}/reports/`,
          });
        }
      }
    } catch {}
    await this.loadCurrentLanguage();
    this.show = true;
    /* Disable standard browser drag and drop */
    window.addEventListener(
      "dragover",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    window.addEventListener(
      "drop",
      function (e) {
        e = e || event;
        e.preventDefault();
      },
      false
    );
    // Keeps the current logged-in user session alive
    const debouncedKeepAlive = debounce(this.keepLoginAlive, 10000, { maxWait: 10000, leading: true })
    document.addEventListener('mousemove', debouncedKeepAlive)
    document.addEventListener('keydown', debouncedKeepAlive)

    this.changeFavicon();
  },
  data() {
    return {
      show: false,
      isMac: false,
      selectFocussed: false,
      showMailTriggerDialog: false,
      key: Date.now(),
      imageNotFound: false,
      showDownloadReportDialog: false,
      showMenu: false,
    };
  },
  setup() {
    const DownloadReportsDialog = ref(null);
    return { DownloadReportsDialog };
  },
  computed: {
    ...mapGetters([
      "isWhb",
      "isLteAdmin",
      "allowedToEditBranding",
      "isCallOperator",
      "questions",
      "isSupport",
      "currentUserOrganization",
      "isTranslator",
      "supportEmail",
    ]),
    ...mapState({
      authToken: (state) => state.authToken,
      userData: (state) => state.userData,
      emailAlreadyExistsDialog: (state) => state.emailAlreadyExistsDialog,
      currentOrganizationId: (state) => state.currentOrganizationId,
      currentReportId: (state) => state.currentReportId,
      reports: (state) => state.reports,
      currentReport: (state) => state.currentReport,
      showDefaultError: (state) => state.showDefaultError,
      sessionExpired: (state) => state.sessionExpired,
      errorMessage: (state) => state.errorMessage,
      showDeleteReportDataDialog: (state) => state.showDeleteReportDataDialog,
      questionIndex: (state) => state.questionIndex,
      organizations: (state) => state.organizations,
      show404Page: (state) => state.show404Page,
      showWelcomePopup: (state) => state.showWelcomePopup,
      isGraphQlError: (state) => state.error.isGraphQlError,
      defaultSnackbars: (state) => state.defaultSnackbars,
      planExpires: (state) => state.planExpires,
      defaultSnackbarText: (state) => state.defaultSnackbarText,
      appRequest: (state) => state.appRequest,
      showDefaultLoading: (state) => state.showDefaultLoading,
      forcedLanguages: (state) => state.forcedLanguages,
    }),
    showEmailAlreadyExists: {
      set(value) {
        this.setEmailAlreadyExistsDialog(value);
      },
      get() {
        return this.emailAlreadyExistsDialog;
      },
    },
    showMailTrigger() {
      return [
        "localhost",
        "app-test.whistle-report.com",
        "lte-dev.azurewebsites.net",
      ].includes(window.location.hostname);
    },

    logoFileName() {
      // eslint-disable-next-line camelcase
      const filename =
        this.organizations.find(
          (organization) => organization.id === this.currentOrganizationId
        )?.logo_filename || "logo";
      return `_${filename}`;
    },
  },
  methods: {
    ...mapActions([
      "addCurrentOrganizationToSession",
      "loadUserData",
      "loadCurrentReport",
      "logout",
      "loadAllPricingPlanTemplates",
      "changeUserLanguage",
      "initializeI18nLanguages",
      "loadCurrentLanguage",
      "changeFavicon",
      "refreshSession",
      "initializeI18nLanguage",
      "loadLanguage",
      "loadRoles",
      "keepLoginAlive"
    ]),
    ...mapMutations([
      "setPlanExpires",
      "setEmailAlreadyExistsDialog",
      "setCurrentReportId",
      "hideDefaultError",
      "resetErrorMessage",
      "resetNewReportStatements",
      "setIsGraphQlError",
      "setSessionExpired",
      "changeLanguage",
      "hideDefaultSnackbar",
    ]),
    isLoginOrReportPage() {
      return (
        (this.userData && !this.userData.setup_completed) ||
        [
          "Cookies",
          "Login",
          "SignupPayment",
          "GeneralLogin",
          "Default404",
          "Signup",
          "SignupPlan",
          "SignupConfirmation",
          "SetupLogin",
          "SetupPasswordReset",
          "SetupBranding",
          "Report",
          "AccessReport",
          "Faq",
          "CustomLegalNotice",
          "CustomDataProtection",
          "CustomAccessibilityDeclaration",
        ].includes(this.$route.name) ||
        (this.userData && this.userData.requires_password_change)
      );
    },
    calculateHeaderHeight() {
      if (this.appRequest.showAppBar){
        return this.$vuetify.display.width <= 1000 ? '60px' : '100px'
      }
      return '0';
    },
    calculateHeaderWidth(){
      if (this.isLoginOrReportPage()){
        return this.$vuetify.display.width >= 1000 ? 'calc((100% - 400px) - 0px)':'100%'
      }
      else{
        return this.userData && !this.isCallOperator ? 'calc((100% - 265px) - 0px)':'100%'
      }
    },
    openSessionExpiredWindow() {
      if (this.isWhb) {
        if (this.currentReport) {
          window.open(
            `${this.currentReport.report_number}/pin?sessionExpired=1`
          );
        } else {
          window.location.pathname = `/report/${router.currentRoute.value.params.companyHash}`;
        }
      } else {
        window.open(
          `${
            this.userData && this.userData.organization_id
              ? `/${this.userData.organization_id}`
              : ""
          }/login?sessionExpired=1`
        );
      }
    },

    showPinPage() {
      return this.questionIndex === "PIN";
    },

    showIdPage() {
      return this.questionIndex === "ID";
    },

    copyReportNr() {
      const el = document.createElement("textarea");
      el.value = this.currentReport.report_number;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.showSnackbar = true;
    },

    loadReport(id) {
      this.setCurrentReportId(id);
      this.loadCurrentReport();
    },
    downloadReport() {
      this.setCurrentReportId(this.currentReport.id);
      this.showDownloadReportDialog = true;
      this.$refs.DownloadReportsDialog.downloadReports([this.currentReportId]);
    },

    calcMargin(i) {
      return i * 60 + "px";
    },

    handleCancel() {
      if (this.isWhb) {
        this.openSessionExpiredWindow();
      } else {
        this.logout();
      }
    },
    handleSubmit() {
      if (this.isWhb) {
        this.refreshSessionExpiration();
      } else {
        this.openSessionExpiredWindow();
      }
    },

    async refreshSessionExpiration() {
      const response = await this.refreshSession();
      if (response.status === 200) {
        this.setSessionExpired(false);
      } else {
        console.log("refreshing session failed");
      }
    },
  },

  watch: {
    authToken(newSession) {
      if (newSession) {
        this.setSessionExpired(false);
      }
    },
    async currentUserOrganization(newValue) {
      if (newValue && newValue.suspended) {
        // avoids redundant navigation error
        if (this.$route.name !== "PlanSettings") {
          this.$router.push({ name: "PlanSettings" });
        }
      }
    },
  },
};
</script>

<style>
@import "../node_modules/cropperjs/dist/cropper.css";
@import "assets/css/global-style.css";
</style>

<style lang="scss">
@import url("./css/GlobalStyles.scss");
@import url("./css/VuetifyAdjustments.css");

.mfa-dialog {
  overflow: visible !important;
}
.primary-color-elevated{
  background-color: var(--v-primary-base) !important;
  color: white !important;}
</style>

<style scoped lang="scss">
:deep(.error-text a) {
  color: #fff !important;
}

h3:hover {
  cursor: pointer;
}

.v-select,
.v-list {
  background: white;
  color: #fff !important;
}

:deep(header) {
  background-color: #fff !important;
}

.logo {
  max-width: 350px;
  max-height: 80px;
}

.logo-small {
  max-width: 125px;
  max-height: 21px;
  width: auto;
  height: 60px;
}

.title-text {
  margin-left: 50px;
  font-weight: bolder;
  font-size: 20px;
}

.title-text-small {
  margin-left: 10px;
  font-weight: bolder;
  font-size: 15px;
}

.test-features,
:deep(.test-features) {
  display: grid;
  grid-template-columns: max-content max-content;
  height: 60px;
  align-items: center;

  button:hover {
    cursor: pointer;
  }

  button,
  .v-select,
  .v-icon {
    opacity: 0;
    transition: all 0.4s ease;
  }

  &:hover {
    button,
    .v-select,
    .v-icon {
      opacity: 1;
    }
  }

  &.selectFocussed {
    button,
    .v-select,
    .v-icon {
      opacity: 1;
    }
  }
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.v-btn:not(.v-btn--icon):not(.v-btn--rounded).download-reports-btn {
  min-width: 40px !important;
  width: 40px !important;
}
:deep(.v-btn--variant-elevated) {
  box-shadow: none;
}
:deep(.v-btn--variant-flat) {
  box-shadow: none;
  background: transparent;
}
:deep(.v-toolbar__content) {
  margin: auto 0;
  padding: 4px 16px;
}
</style>
