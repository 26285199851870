export default {
  setAllUsers (state, users) {
    state.users = users
  },

  setCurrentUserMFAPrefernce (state, mfaPreference) {
    const currentUser = state.users.filter(item => item.id === state.userData.id)[0]
    if (!currentUser) {
      throw new Error('Current user was null when MFA preference was being set.')
    }
    const currentUserIndex = state.users.indexOf(currentUser)
    const copyCurrentUser = JSON.parse(JSON.stringify(currentUser))
    copyCurrentUser.mfa_method = mfaPreference

    state.users[currentUserIndex] = copyCurrentUser
    state.users = [...state.users]
  },

  setAllUsersFiltered (state, {data, totalCount}) {
    state.usersFiltered = {data, totalCount}
  },

  toggleUserFilter (state, { filter, value }) {
    const isPresent = state.usersTableOptions.filter[filter].includes(value)

    if (isPresent) {
      state.usersTableOptions.filter[filter] = state.usersTableOptions.filter[filter].filter(_value => _value !== value)

    } else {
      state.usersTableOptions.filter[filter] = [...state.usersTableOptions.filter[filter], value]
      console.log(filter)
    }
  },

  updateUser (state, user) {
    state.users = state.users.map(_user => _user.id === user.id ? user : _user
    )
  },

  setEmailAlreadyExistsDialog (state, show) {
    state.emailAlreadyExistsDialog = show
  },

  addUser (state, user) {
    state.users = [...state.users, user]
  },

  setUsersTableOptions (state, {key, value}) {
    state.usersTableOptions[key] = value
  },

  deleteUser (state, user) {
    state.users = state.users.filter(_user => _user.id !== user.id)
  },

  setRequiresPasswordChange (state, value) {
    state.userData.requires_password_change = value
  },

  changeLanguage (state, language) {
    state.language = language
  }

}
